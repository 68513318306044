.page:not(.set--overlay),
.header-container:not(.set--overlay) .header {
    &:after {
        @include set-invisible();
    }
}

.header {
    &:after {
        position: absolute;
    }
}

.page {
    //When an overlay is applied to the .page, set it as fixed so that it
    //always fills the entire screen.
    &:after {
        position: fixed;
    }
}

.loader-container {
    position: relative;
}

/*------------------------------------*\
  #OVERLAYS
\*------------------------------------*/


.set--overlay {
    position: relative;
    //Helps iOS detect taps on overlaid pseudo-element.
    cursor: pointer;
}

//Forces overlay on top of everything.
//Limit to 20, so that other components can "punch through".
.set--overlay-all {
    &:after {
        z-index: 1080;
    }
}

.loader,
.set--overlay:after {
    position: absolute;
}

.loader,
.set--overlay:not(.header-container):after,
.page:after,
.header:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
}

.set--overlay:not(.header-container):after,
.page:after,
.header:after {
    background-color: transparentize($color-black, 0.75);
    transition: opacity $transition-speed-fast $transition-ease-default,
                visibility $transition-speed-fast $transition-ease-default;
}

.set--overlay:not(.header-container),
.page,
.header {
    &:after {
        content: '';
    }
}

/*------------------------------------*\
  #LOADER
\*------------------------------------*/
//Local variables
$_loader-offset: 187;
$_loader-duration: 1.4s;

.loader {
    z-index: 10000; //It's over 9000!. This should overlay *everything*.
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition-property: opacity, visibility;
    transition-timing-function: $transition-ease-in;
    transition-duration: $transition-speed-slow;

    @include set-invisible();

    &.set--alt {
        background-color: transparentize($color-black, 0.75);

        .loader-indicator__path {
            //stroke: $color-white;
        }
    }

    &:not(.set--alt) {
        background-color: transparentize($color-white, 0.75);

        .loader-indicator__path {
           // stroke: $color-secondary;
        }
    }

    &.set--fixed {
        position: fixed;
        .loader-indicator, .face-loader {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &.set--visible {
        transition-timing-function: $transition-ease-out;
        transition-duration: $transition-speed-slow;
        @include set-visible();
    }
}

.face-loader-wrapper {
    #eye-sx, #eye-dx {
        fill: #c10016;
        opacity: 0;
        animation: eye 500ms 300ms ease infinite;
    }
}

    .loader-indicator {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        max-width: 5rem;
        max-height: 5rem;
        pointer-events: none;
    }

        .loader-indicator__path {
            fill: #949494;
        }

        .hide {
            position: absolute;
            width: 0;
            height: 0;
        }

        .face-loader {
            position: absolute;
            top: 0;
            width: 80px;
            height: 80px;
            display: block;
            clip-path: url(#clipping-face);
            &:before {
                content: '';
                display: block;
                background: #c10016;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: translateY(-100%);
                animation: loader-horizontal 500ms 300ms ease forwards;
                transform-origin: 0 0;
            }
        }

        .face-loader--reverse {
            &:before {
                transform: translateY(100%);
                animation: loader-horizontal-reverse 500ms 300ms ease forwards;
            }
        }

    .loader-message {
        max-width: 20.625rem;
        padding: 0 1rem;
        text-align: center;
        font-weight: $font-weight-bold;
        color: $color-secondary;
        background-color: transparentize($color-white, .25);
    }

//Credit: https://codepen.io/mrrocks/pen/EiplA
/*! rtl:begin:ignore */
@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

@keyframes dash {
    0% { stroke-dashoffset: $_loader-offset; }

    50% {
        stroke-dashoffset: $_loader-offset/4;
        transform:rotate(135deg);
    }

    100% {
        stroke-dashoffset: $_loader-offset;
        transform:rotate(450deg);
    }
}

    @keyframes loader-horizontal {
        from {
            transform: translateY(-100%);
        }
        to {
            transform: translateY(0);
        }
    }

    @keyframes loader-horizontal-reverse {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(0);
        }
    }

@keyframes eye {
    0% {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 0.9;
    }
    60% {
      opacity: 1;
    }
    80% {
      opacity: 0.6;
    }
    100% {
      opacity: 0.4;
    }
  }
/*! rtl:end:ignore */
