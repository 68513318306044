
.footer-partners {
    border-top: solid .0625rem $color-grey-3;
}

.max-width--footer-partners {
    &.row {
        margin: 0 auto;
    }
    max-width: 34.25rem;
}

@include media-query(small-up) {
    .footer-partners {
        padding-top: 2.5rem;
    }

        .footer-partners__img {
            height: 2rem;
        }
}

@include media-query(small) {
    .footer-partners {
        margin-top: 1.125rem;
        padding-top: 3rem;
        padding-bottom: 1.5rem;
    }

        .footer-partners__item {
            &:not(:last-child) {
                margin-bottom: 2.75rem;
            }
        }

        .footer-partners__img {
            height: 2.375rem;
        }
}
