
@include media-query(medium-up) {
    .store-locator__map {
        @include aspect-ratio(876, 464, true);
    }
}

@include media-query(small-up-to-medium) {
    .store-locator__map {
        @include aspect-ratio(600, 450, true);
    }
}

@include media-query(small-up) {
    .store-locator__map-results {
        position: relative;

        .store-locator__results {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: 100%;
        }
    }
}

@include media-query(small) {
    .store-locator__map {
        @include aspect-ratio(330, 380, true);

        .store-locator__results {
            margin-bottom: 2rem;
        }
    }

    .store-locator__results {
        max-height: 22rem;
    }
}