.header__minicart-overlay {
    .product-line-item__attributes {
        .product-line-item__bonus {
            display: none;
        }
    }
}

.minicart__checkout-action {
    &:not(:first-child) {
        margin-top: 1rem;
    }

    &.button--apple-pay {
        &, &:hover, &:active {
            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }
}

@include media-query(small-up) {
    .header__minicart-overlay {
        width: 31.25rem;
    }
}

@include media-query(small) {
    .header__minicart-overlay {
        width: 100%;
    }
}
