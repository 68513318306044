/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

button {
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
    font: inherit;
    letter-spacing: inherit;
}

.button {
    //For non-button tags:
    display: inline-block;
    padding: .75em 1.85em;
    border: solid .0625rem;
    border-radius: $input-border-radius;
    font-size: $font-size-centi;
    font-weight: $font-weight-semibold;
    text-align: center;
    text-transform: uppercase;
    transition: opacity $transition-speed-normal $transition-ease-bezier,
                color $transition-speed-normal $transition-ease-bezier,
                border-color $transition-speed-normal $transition-ease-bezier,
                background-color $transition-speed-normal $transition-ease-bezier;

    @include bidirectionalProperty(letter-spacing, $font-letter-spacing-deci, 0);

    &.remove--border-left-radius {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.remove--border-right-radius {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:hover:not([disabled]):not(.disabled) {
        opacity: 0.7;
    }

    &[disabled], &.disabled {
        background-color: $color-grey-2;
        border-color: $color-grey-2;
        color: $color-grey-5;
        cursor: not-allowed;
    }
}

.button--small {
    padding: .45em 1em;
}

.button--large {
    padding: 1em;
}

//Necessary override to avoid some difficult choices.
.button--flex {
    display: flex;
}

//Allows the contents of the button to define its width.
//Helpful when the button's default padding is too large.
.button--fluid {
    padding-right: 0;
    padding-left: 0;
}

.button--primary {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $color-white;
    cursor: pointer;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-white;
        color: $color-primary;
    }
}

.button--primary-outline {
    color: $color-primary;
    cursor: pointer;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white;
    }
}

.button--secondary {
    background-color: $color-secondary;
    border-color: $color-secondary;
    color: $color-white;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-white;
        color: $color-secondary;
    }
}

.button--secondary-outline {
    color: $color-secondary;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-secondary;
        border-color: $color-secondary;
        color: $color-white;
    }
}

.button--tertiary {
    background-color: $color-tertiary;
    border-color: $color-tertiary;
    color: $color-white;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-white;
        color: $color-tertiary;
    }
}

.button--tertiary-outline {
    color: $color-tertiary;

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: $color-tertiary;
        border-color: $color-tertiary;
        color: $color-white;
    }
}

.button--custom {
    background-color: var(--component-var-background-color, $color-black);
    border-color: var(--component-var-background-color, $color-black);
    color: var(--component-var-color, $color-white);

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: transparent;
        color: var(--component-var-background-color, $color-black);
    }
}

.button--custom-outline {
    color: var(--component-var-background-color, 'currentColor');

    &:hover:not([disabled]):not(.disabled) {
        opacity: 1;
        background-color: var(--component-var-background-color, 'currentColor');
        border-color: var(--component-var-background-color, 'currentColor');
        color: var(--component-var-color, $color-white);
    }
}

.button--cross-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.5em;
    height: 2.5em;
    transition: opacity $transition-speed-normal $transition-ease-bezier;

    &:hover, &:focus {
        opacity: .5;
    }
}

.button--circle-close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.625em;
    height: 1.625em;
    border-radius: 50%;
    border: solid .0625rem $color-grey-3;
    transition: opacity $transition-speed-normal $transition-ease-bezier;

    &:hover, &:focus {
        opacity: .5;
    }
}

    .button__icon--circle-close {
        //Intentionally set to em
        font-size: .75em;
    }

.button--paypal {
    overflow: hidden;
    display: block;
    position: relative;
    height: 3rem;
    padding: 0;
    background-image: url('../images/payment-logos/PayPal_logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 6.2rem;

    //Aligns the paypal button by using an absurd width
    //to force the API to add a large height.
    .paypal-button {
        @include vertical-align(true, true);
        //PayPal adds width: 100%; on a randomly generated element ID.
        width: 100% !important;
        height: 100% !important;
        opacity: 0.000000001; //For some reason, Chrome doesn't let click throughs into an iframe if set to 0.

        //This is a necessary evil to force
        //PayPal button container to not use a fixed height
        .xcomponent-outlet,
        .zoid-outlet,
        .zoid-component-outlet {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

//SFCC's Apple Pay buttons require some extra specifity.
.button--apple-pay.dw-apple-pay-button {
    &, &:hover, &:active {
        margin: 0;
        margin-top: .5em;
        min-height: 2.1875rem;
        border-radius: 0;
    }
}

.button {
    &.apple-pay-cancel-checkout, 
    &.applepay-checkout {
        margin-top: 1rem;
    }

    &[data-applepay-instant-checkout="button"] {
        background-color: black;
        background-image: -webkit-named-image(apple-pay-logo-white);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 75% 60%;
        box-sizing: border-box;
        margin: 0.5rem auto;
        min-height: 38px;
        min-width: 100px;
        padding: 0;
        &:hover:not([disabled]):not(.disabled),
        &:focus:not([disabled]):not(.disabled),
        &:active:not([disabled]):not(.disabled) {
            background-color: black;
            background-image: -webkit-named-image(apple-pay-logo-white);
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }
    }
}

.shippingAddressSummary {
    position: relative;
    .modifyShippingAddress {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.addressSummary {
    margin: 0.75rem 0;
    .invalid-feedback {
        font-size: 1rem;
    }
}

/*------------------------------------*\
  #CHIPS
\*------------------------------------*/

.chip {
    display: inline-block;
    min-width: 3em;
    padding: .3em 0.5em;
    border: solid .0625rem $color-grey-4;
    border-radius: $input-border-radius;
    text-align: center;
    font-weight: $font-weight-bold;

    &:hover {
        border-color: $color-grey-7;
    }

    &.selected {
        border-color: $color-primary;
        background-color: $color-primary;
        color: $color-white;
    }
}

/*------------------------------------*\
  #PILLS
\*------------------------------------*/

.pill {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .4em 1em;
    border: solid .0625rem;
    text-transform: uppercase;

    &:hover {
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white;
    }
}

.pill--icon-left {
    padding-left: .5em;
}

.pill--icon-right {
    padding-right: .5em;
}

    .pill__icon--actionable {
        &:hover {
            opacity: .5;
        }
    }

    .pill__icon--swatch {
        border: solid .0625rem $color-white;
    }

    .pill__icon--left {
        margin-right: .5em;
    }

    .pill__icon--right {
        margin-left: 1em;
    }
