@include media-query('small-up') {
    .header-flyout__item {
        .header-flyout__list--generic &.level-3 {
            margin-top: 1rem;
        }
    }

    .header-flyout__anchor {
        &.level-2.cust-border {
            border-right: 1px solid #000;
            border-right-width: 1px;
            padding-top: 4px;
            padding-bottom: 2px;

            &:after {
                top: 28px;
            }
        }
    }
}