
.header-flyout__container {
    &.level-1 {
        color: $header-nav-color;
    }

    &.level-2 {
        color: $header-nav-panel-color;
    }
}

.header-flyout__anchor {

    &.level-2.cust-border {
        border-right: 1px solid #000;
        border-right-width: 1px;
        padding-top: 4px;
        padding-bottom: 2px;

        &:after {
            top: 28px;
        }
    }
}

.header-flyout__close {
    color: $header-nav-panel-color;
}

.header-flyout__anchor:not(.header-flyout__anchor--parent) {
    @include bidirectionalProperty(font-size, $font-size-centi, $font-size-deci);
}

.header-flyout__anchor-arrow {
    @include bidirectionalProperty(transform, $rtlValue: scale(-1));
}

/*------------------------------------*\
  #FLYOUT-TABS
\*------------------------------------*/
.header-flyout__item--thumbnail {
    padding: 0 .5rem;
}

.header-flyout__anchor--thumb {
    display: block;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}

    .header-flyout__tab-viewall {
        margin-top: 1.35rem;
    }

    .header-flyout__thumb-img {
        margin-bottom: .95rem;
    }


/*------------------------------------*\
  #FLYOUT-EXTRAS
\*------------------------------------*/
.header-flyout__extra {
    position: relative;
    padding-top: 2.125rem;
    // max-width: 56.25rem;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        max-width: 46.75rem;
        margin: auto;
        border-top: solid .0625rem $color-grey-3;
        content: '';
    }

    .heading-extra {
        padding-bottom: 1.5rem;
    }
}

    .header-flyout__extra-list {
        justify-content: center;
        margin-top: 1.5rem;
    }

@include media-query('large-up') {
    .header-flyout__item--links {
        .header-flyout__list--generic &.level-2 {
            padding-left: 1.75vw;
            padding-right: 1.75vw;
        }
    }
}

@include media-query('medium-up') {
    .header-flyout__list--generic {
        &.level-2 {
            justify-content: center;
        }
    }

    /*------------------------------------*\
      #FLYOUT-EXTRAS
    \*------------------------------------*/
    .header-flyout__item--extra {
        margin-left: 2.4375rem;
        margin-right: 2.4375rem;
    }
}


@include media-query('small-up-to-large') {
    .header-flyout__item--links {
        .header-flyout__list--generic &.level-2 {
            padding-left: 1.75vw;
            padding-right: 1.75vw;
        }
    }
}

@include media-query('small-up-to-large') {
    .header-flyout__list--generic {
        &.level-2 {
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    /*------------------------------------*\
      #FLYOUT-EXTRAS
    \*------------------------------------*/
    .header-flyout__item--extra {
        margin-left: 1.25rem;
        margin-right: 1.25rem;
    }
}

@include media-query('small-up') {
    .header-flyout__list {
        &.level-1 {
            display: flex;
        }
    }

    .header-flyout__list--generic {
        &.level-2 {
            display: flex;
            align-content: flex-start;
        }
    }

    .header-flyout__list--sections {
        &.level-2 {
            text-align: center;
        }
    }

        .header-flyout__item {
            .header-flyout__list--generic &.level-2 {
                margin-bottom: 3.5rem;
            }

            .header-flyout__list--generic &.level-3 {
                margin-top: 1.875rem;
            }
        }

        .header-flyout__item--tile {
            .header-flyout__list--generic &.level-2 {
                flex-basis: 22.5rem;
                max-width: 22.5rem;
            }
        }

        .header-flyout__anchor {
            &.level-1 {
                position: relative;
                display: block;
                transition-property: opacity;
                transition-duration: $transition-speed-fast;
                transition-timing-function: $transition-ease-default;

                -webkit-text-stroke-width: 0;

                @include bidirectionalProperty(letter-spacing, $font-letter-spacing-hecto, 0);

                &:after {
                    position: absolute;
                    right: .725rem;
                    width: 0;
                    height: .1875rem;
                    background-color: $color-secondary;
                    content: '';

                    transition-property: width;
                    transition-duration: $transition-speed-fast;
                    transition-timing-function: $transition-ease-in;
                }

                &.mega-item--active {
                    -webkit-text-stroke-width: .0625rem;

                    &:after {
                        left: .725rem;
                        right: auto;
                        width: calc(100% - 1.45rem);

                        // transition-duration: $transition-speed-fast;
                        transition-timing-function: $transition-ease-out;
                    }
                }
            }

            .header--logo-has-copy &.level-1 {
                padding: .5rem .9375rem 1.5rem;

                &:after {
                    bottom: 1.5em;
                }
            }

            .header--logo-no-copy &.level-1 {
                padding: 1.25rem .9375rem 1.925rem;

                &:after {
                    bottom: 2em;
                }
            }
        }

        .header-flyout__anchor--parent {
            font-size: $font-size-base;
            font-weight: $font-weight-semibold;
            pointer-events: none;
        }

        .header-flyout__container {
            &.level-2 {
                z-index: 1;
                overflow: hidden;
                position: absolute;
                left: 0;
                width: 100vw;
                background-color: $header-nav-panel-bg;
                box-shadow: inset 0 .0625rem 0 0 transparentize($color-black, .85);

                transition: max-height $transition-speed-fast $transition-ease-in;

                > .header-flyout__scrollable {
                    //Fix Safari odd jump on first time when navigating into the site.
                    transform: translate3d(0,0,0);

                    transition-property: opacity, visibility;
                    transition-duration: $transition-speed-fast;
                    transition-timing-function: $transition-ease-in;
                }

                &.mega-item--active {
                    // Set a default max-height for IE which does not support min().
                    max-height: calc(100vh - 12.5rem);
                    max-height: min(41.25rem, calc(100vh - 12.5rem));
                    overflow-y: scroll;
                    > .header-flyout__scrollable {
                        transition-delay: $transition-speed-faster;

                        @include set-visible();
                    }
                }

                &:not(.mega-item--active) {
                    z-index: 2;
                    max-height: 0;

                    > .header-flyout__scrollable {
                        transition-duration: $transition-speed-faster;

                        @include set-invisible();
                    }
                }

                .mega--active &.mega-item--active {
                    > .header-flyout__scrollable {
                        transition-delay: $transition-speed-normal;
                    }
                }

                .mega--active &:not(.mega-item--active) {
                    transition-delay: $transition-speed-fastest;
                }
            }
        }

        .header-flyout__scrollable {
            &.level-2 {
                padding-top: 1.675rem;
                padding-bottom: 1.875rem;
            }
        }

    /*------------------------------------*\
      #FLYOUT-TABS
    \*------------------------------------*/
    .header-flyout__anchor--tab {
        display: inline-block;
        padding: .75em 1.25em;

        &.level-2 {
            position: relative;
            &:hover, &:focus, &.mega-item--active {
                -webkit-text-stroke-width: .0625rem;
            }
            &::after {
                position: absolute;
                right: .725rem;
                width: 0;

                bottom: 0;
                height: .1875rem;
                background-color: $color-secondary;
                content: '';

                transition-property: width;
                transition-duration: $transition-speed-fast;
                transition-timing-function: $transition-ease-in;
            }
            &.mega-item--active {

                &:after {
                    left: .725rem;
                    right: auto;
                    width: calc(100% - 1.45rem);

                    // transition-duration: $transition-speed-fast;
                    transition-timing-function: $transition-ease-out;
                }
            }
        }
    }

    .header-flyout__tab-list {
        display: block;
        padding-left: .4rem;
        padding-right: .4rem;
        border-bottom: solid .0625rem $color-grey-3;
    }

        .header-flyout__tab-containers {
            position: relative;
            padding-top: .5rem;
        }

        .header-flyout__tab-content {
            width: 100%;
            max-height: 100vh;
            transition: max-height 0s $transition-ease-in,
                        opacity $transition-speed-fast $transition-ease-in,
                        visibility $transition-speed-fast $transition-ease-in;

            &:not(.mega-item--active) {
                max-height: 0;
                overflow: hidden;
                @include set-invisible();
            }
        }

        .header-flyout__container.level-2:not(.mega-item--active) {
            .header-flyout__tab-content {
                transition: max-height 0s $transition-ease-in $transition-speed-slow,
                            opacity $transition-speed-fast $transition-ease-in,
                            visibility $transition-speed-fast $transition-ease-in;
            }
        }

    /*------------------------------------*\
      #FLYOUT-EXTRAS
    \*------------------------------------*/

    .header-flyout__extra {
        margin-top: 2.25rem;
    }

        .header-flyout__extra-list {
            display: flex;
        }

        .header-flyout__item--extra {
            align-items: center;
        }

    /*------------------------------------*\
      #FLYOUT-CAROUSEL
    \*------------------------------------*/

    .header-flyout__tab-carousel {
        width: 60rem;
        margin: 0 auto;

        .slick-arrow {
            @include vertical-align($transform: true, $posAdj: -1.25rem);
        }

        .slick-prev {
            left: -3rem;
            &:hover {
                transform: translate(-10%, -50%);
            }
        }

        .slick-next {
            right: -3rem;
            &:hover {
                transform: translate(10%, -50%);
            }
        }
    }

}

@include media-query('small') {
    .header-flyout {
        z-index: 6;
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $header-nav-panel-bg;
        // overflow: hidden;
        transition-property: transform;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-out;
        white-space: nowrap;

        &:not(.flyout-toggle--active) {
            transform: translateX(-100%);
            @include set-invisible();
        }

        &.flyout-toggle--active {
            transform: translateX(0);
            @include set-visible();

            .header-flyout__anchor.level-1,
            .header-flyout__secondary-link {
                transform: translateX(0);
            }
        }
    }

        .header-flyout__head {
            position: relative;
            height: 4.75rem;
        }

            .header-flyout__close {
                z-index: 2;
                position: absolute;
                left: 0;
                bottom: .5rem;
                padding: 1rem 1.25rem;
            }

            .header-flyout__logo {
                max-width: 6.8rem;

                .header--logo-has-copy & {
                    height: 3.45rem;
                }

                .header--logo-no-copy & {
                    height: 1.75rem;
                }
            }

            .header-flyout__back {
                // position: absolute;
                // left: 0;
                // bottom: 100%;
                // opacity: 0;
                // background-color: $header-nav-panel-bg;

                // transition-property: opacity, visibility;
                // transition-duration: 0s;
                // transition-timing-function: $transition-ease-default;
            }

        .header-flyout__container {
            &.level-1 {
                position: relative;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                //Substract the height of the menu's top bar.
                max-height: calc(100% - 4.75rem);
            }

            &:not(.level-1) {
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $header-nav-panel-bg;
                transition-property: transform;
                transition-duration: $transition-speed-fast;
                transition-timing-function: $transition-ease-in;

                &.mega-item--active {
                    transform: translateX(0);
                    transition-timing-function: $transition-ease-out;
                    @include set-visible();

                    > .header-flyout__back {
                        // opacity: 1;

                        // transition-duration: $transition-speed-fast;
                        // transition-delay: $transition-speed-fast;
                    }
                }

                &:not(.mega-item--active) {
                    transform: translateX(100%);
                    @include set-invisible();
                }
            }
        }

            .header-flyout__scrollable {
                flex-grow: 1;
                overflow-y: auto;
                overflow-x: hidden;
                // -webkit-overflow-scrolling: touch;

                &.level-1 {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                &:not(.level-1) {
                    height: calc(100% - 6rem);
                    padding-bottom: 6rem;
                }
            }

            .header-flyout__anchor,
            .header-flyout__back,
            .header-flyout__secondary-link {
                display: flex;
                align-items: center;
            }

            .header-flyout__anchor {
                padding: 1.45rem 2.65rem 1.45rem 1.625rem;
            }

            .header-flyout__secondary-link {
                padding: 1.25rem 0 1.25rem 1.625rem;
            }

            .header-flyout__back {
                padding: 1.45rem 2.65rem 1.45rem 1rem;
            }

            .header-flyout__anchor.level-1,
            .header-flyout__secondary-link {
                transform: translateX(-5rem);

                transition-property: transform;
                transition-duration: $transition-speed-fast;
                transition-timing-function: $transition-ease-out;
                transition-delay: $transition-speed-faster;
            }

            .header-flyout__anchor--parent {
                // font-weight: $font-weight-bold;
            }

        .header-flyout__secondary {
            position: relative;
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
            padding-right: 7rem;
            border-top: solid .0625rem $color-grey-2;
        }

            .header-flyout__secondary-link {
                padding: .5rem 1.25rem;
                color: $color-grey-5;
            }

    /*------------------------------------*\
      #FLYOUT-TABS
    \*------------------------------------*/

    .header-flyout__tab-carousel {
        display: flex;
        flex-wrap: wrap;
    }

        .header-flyout__item--thumbnail {
            flex: 1 0 50%;
            max-width: 50%;
            white-space: normal;
        }

    /*------------------------------------*\
      #FLYOUT-EXTRAS
    \*------------------------------------*/

    .header-flyout__extra {
        margin-top: 2.95rem;
    }

        .header-flyout__item--extra {
            align-items: start;

            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
}

[lang="TH-TH"] {
    .header-flyout__anchor.level-1.mega-item--active, 
    .header-flyout__anchor.level-2.mega-item--active,
    .header-flyout__anchor.level-2:hover,
    .header-flyout__anchor.level-2:focus {
        -webkit-text-stroke-width: 0;
    }
}

html[lang="th-TH"] {
    .header-flyout__item {
        .header-flyout__container {
            .level-3 {
                .link--primary {
                    font-size: 0.8rem;
                }
            }
        }
    }

    .pdp-main__short-description,
    .pdp-main__description,
    .contactus-tile__link {
        font-size: 0.8rem;
    }

    .descriptive-card__content {
        .descriptive-card__title {
            font-size: 1rem;
        }

        .descriptive-card__description, 
        .descriptive-card__actions {

            font-size: 0.8rem;
        }
    }

    .product-tile__body {
        .product-tile__body-section {
            font-size: 0.8rem;
        }
    }
}

@media screen and (max-width: 47.9375rem) {
    html[lang="th-TH"] {
        .header-flyout__anchor:not(.header-flyout__anchor--parent),
        .header-flyout.flyout-toggle--active .header-flyout__secondary-link,
        .header-flyout.flyout-toggle--active .header-flyout__secondary-link .header__utility-label,
        .header-language-selector,
        .header-flyout .body-type--centi,
        .header-flyout__extra .header-flyout__item a {
            font-size: 1rem;
        }
    }    
}

@include media-query('small-up') {
    .header-flyout__list--sections {
        position: relative;
        max-width: 47rem;
        margin: 0 auto;
    }
    .header-flyout__tab-list {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        &::-webkit-scrollbar {
            display: none;
        }
        & > li {
            display: inline-block;
        }
    }
    .header-flyout__arrows {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        .header-flyout__arrow {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 2.375rem;
            height: 2.375rem;
            font-size: 0.75rem;
            background: #fff;
        }
        .header-flyout__left-arrow {
            left: 0;
        }
        .header-flyout__right-arrow {
            right: 0;
        }
    }
}

@include media-query('medium-up') {
    .header-flyout__list--sections {
        max-width: 60rem;
    }
    .header-flyout__arrows {
        .header-flyout__left-arrow {
            left: -2rem;
        }
        .header-flyout__right-arrow {
            right: -2rem;
        }
    }
}