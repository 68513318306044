.product-grid__item {
    &.product-gridItem__last {
        @include media-query(small-up) {
            .quick-shop-active {
                position: relative;
                bottom: auto;
                left: auto;
                right: auto;
                animation: none;
                width: 100%;
            }
        }
    }
    @include media-query(small-up) {
        margin-bottom: 1rem;
    }
}

.product-grid {
    &:not(.set--show-all-products) {
        .product-grid__item--hideable {
            display: none;
        }
    }
}

.product-grid__cta-more {
    &.set--show-all-products {
        .product-grid__cta-label--show {
            display: none;
        }
    }

    &:not(.set--show-all-products) {
        .product-grid__cta-label--hide {
            display: none;
        }
    }
}

.search-results__container {
    .product-grid__item--interrupter {
        .component-overlay {
            &.component-overlay--low-index {
                z-index: 0;
            }
        }
    }
}
