
.product-attribute__list--quickadd {
    .product-attribute {
        position: relative;

        &:not(:last-child) {
            margin-bottom: 1.25rem;
        }
    }

        .product-attribute__swatch {
            &:not(:last-child) {
                margin-right: .625rem;
            }
        }

        .product-attribute__label-pre,
        .product-attribute__selected-value {
            display: none;
        }
}