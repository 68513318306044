@include media-query(medium-up) {
    .footer__country-selector-anchor {
        margin-left: 0.75rem;
    }
}

@include media-query(small-up) {
    .footer {
    }

    .footer__main-content--checkout {
        border-top: solid 0.0625rem $color-grey-3;
    }

    .footer__main-content {
        margin-top: 2.75rem;
        margin-bottom: 3rem;
    }

    .footer__main-content--checkout {
        //Uses padding to account for border styles.
        padding-top: 2.25rem;
        margin-bottom: 2.5rem;
        .footer-content-col {
            flex: 1 1 25%;
            padding: 5px;
        }
    }

    .footer__secondary-content {
        margin-top: 2.5rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
}

@include media-query(medium) {
    .footer__country-selector-anchor {
        margin-top: 0.5rem;
    }
}

@include media-query(small) {
    .footer {
    }

    .footer__separator {
        margin-top: 3.125rem;
        margin-bottom: 3.125rem;
    }

    .footer__main-content {
        margin-top: 2.25rem;
    }

    .footer__main-content--checkout {
        margin-bottom: 2.625rem;
    }

    .footer__secondary-content {
        margin-top: 1.25rem;
        padding-top: 2.25rem;
        padding-bottom: 2.25rem;
    }

    .footer__secondary-content--checkout {
        padding-bottom: 5.5rem;
    }

    .footer__secondary-content-column {
        &:not(:last-child) {
            margin-bottom: 3rem;
        }
    }
}

.usp-footer-banner {
    margin-top: 20px;
}

.footer {
    z-index: 2;
}

#sixtyfps-eoy-wrapper #sixtyfps-eoy-application {
    top: 45px !important;
}

html[lang="th-TH"] {
    .footer__country-selector .footer__country-selector-anchor {
        font-size: 0.7rem;
    }
}

.email-unsubscription-page {
    max-width: 400px;
    margin: 4rem auto;
    padding: 0 1rem;
    h4,
    .descriptive-card__description {
        margin-bottom: 2rem;
    }
    button[type="submit"] {
        margin-top: 2rem;
    }
    .email-unsubscribe-wrapper {
        &.set--invisible {
            display: none;
        }
    }
}

.footer-bottom-ea {
    max-width: 90px;
    @media only screen and (max-width: 767px) {
        width: 100%;
        margin: 0 auto;
    }
    a {
        display: inline-block;
        width: 100%;
    }
}
