.strap-dialog-overlay {
    @include media-query(small) {
        overflow: hidden;
    }
    #forethought-chat {
        @include media-query(small) {
            z-index: 3 !important;
        }
    }
    .product-grid__item {
        @include media-query(small-up) {
            z-index: 0;
        }
        &.index {
            @include media-query(small-up) {
                z-index: 1;
                padding: 0;
            }
        }
    }
}
.product-grid {
    .strap-bar__dailog {
        .pdp-straps {
            &__carousel {
                .pdp-straps__item-image-wrapper {
                    background: none;
                }
            }
        }
    }
    &.feed-view {
        .product-tile {
            &.overlay {
                @include media-query(small) {
                    min-height: 34rem;
                }
            }
            .strap-bar__dailog {
                .pdp-straps {
                    &__carousel {
                        .pdp-straps__item-image-wrapper {
                            @include media-query(small) {
                                max-width: 4.5rem;
                            }
                        }
                    }
                }
                .close-strap {
                    @include media-query(small-up) {
                        right: 8%;
                    }
                }
            }
        }
    }
}
.product-tile {
    &.overlay {
        @include media-query(small-up) {
            min-height: auto;
        }
    }
}

.search-results__container {
    &.index {
        .search-result__toolbar-wrapper {
            @include media-query(small) {
                z-index: 2;
            }
        }
        .product-tile__discover-btn {   
            @include media-query(small) {   
                z-index: 1; 
            }   
        }
    }
}
.window-overlay {
    display: none;
    &.strap-overlay {
        display: block;
        background-color: rgba(0,0,0,.25);
        z-index: 2;
        position: fixed;
        pointer-events: none;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
    }
}
.straps-count {
    cursor: pointer;
    z-index: 2;
    font-size: 1rem;
    line-height: 22px;
    letter-spacing: 0.5px;
    text-transform: lowercase;
    color: $color-black;
    margin-top: 16px;
    display: inline-block;
}

.product-tile {
    &.strap-view-active {
        .strap__wishlist {
            z-index: 5;
            right: 45px;
            top: 10px;
            @include media-query(small-up) {
                padding: 0.36rem;
                width: 24px;
                height: 24px;
                top: 12px;
                right: 56px;
            }
        }
    }
    .strap-bar__dailog {
        .product-add__button {
            &[disabled] {
                &:hover {
                    .add-to-bag__warning {
                        display: none;
                    }
                }
            }
        }
    }
    &.index {
        z-index: 3;
        @include media-query(small) {
            z-index: unset;
        }
        .product-tile__discover-btn {
            @include media-query(small) {
                z-index: unset;
            }
        }
        .product-tile__overlay {
            @include media-query(small-up) {
                height: calc(100% + 8.5rem);
            }
        }

        .product-tile__quickshop,
        .product-tile__wishlist,
        .product-tile__discover-btn,
        .product-tile__quickadd .product-tile__anchor {
            visibility: hidden;
            display: none;
        }

        .strap-bar__dailog {
            &.opacity {
                @include media-query(small-up) {
                    padding: 10px 0 15px;
                    height: auto;
                    left: 0;
                    top: 0;
                    background: $color-white;
                    border: .0625rem solid $color-grey-3;
                }
            }
            .product-tile__quickadd {
                margin-top: 1rem;
                @include media-query(small-up) {
                    padding: 0 20px;
                }
            }

            .slick-list {
                @include media-query(small-up) {
                    margin: 0 20px;
                }
            }
            
            .pdp-straps__item {
                margin: 0 7px;
            }
            .price-detail-wrapper {
                margin-top: 22px;
                @include media-query(small-up) {
                    margin-top: 15px;
                }
            }
        }
        &::after {
            @include media-query(small-up) {
                bottom: -25%;
            }
        }
    }

}

.strap-bar__dailog {
    position: fixed;
    background-color: $color-white;
    opacity: 0;
    height: 0;
    .face-loader {
        display: none;
    }
    @include media-query(small) {
        left: 0;
        right: 0;
        bottom: -10px;
    }
    @include media-query(small-up) {
        position: absolute;
        top: 3.4%;
    }
    &.opacity {
        opacity: 1;
        height: auto;
        z-index: 3;
        padding: 1.5rem;
        @include media-query(small) {
            animation: slide-up .4s 222ms both;
            z-index: 3;
        }

        .product-gallery {
            &.product-gallery--pdp {
                @include media-query(small-up) {
                    padding: 0 30px;
                }
            }
        }

        .button {
            &.product-tile__anchor {
                text-decoration: unset;
                padding: 0.75em 1.85em;
                margin-top: 1rem;
            }
        }
    }
    .strap-name {
        opacity: 1;
        min-height: 24px;
        font-size: 1rem;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: $color-black; 
        margin: 10px 0;
    }
    
    .starps-heading {
        font-size: 0.875rem;
        margin-top:24px;
        text-transform: uppercase;
        font-weight: 600;
    }

    .close-strap {
        position: absolute;
        right: 5%;
        top: 2%;
        z-index: 1;
        background: white;
        border-radius: 50%;
        width: 1.5rem;
        cursor: pointer;
        @include media-query(small-up) {
            right: 10%;
        }

        .button__icon--circle-close {
            width: 0.6rem;
            height: 0.6rem;
        }
    }

    .cursor--pointer {
        display: none;
    }

    .product-gallery__col {
        padding: 0;
        @include media-query(small) {
            padding: 0 5rem 0 4rem;
        }
    }

    .starp-short-dec {
        font-size: 0.875rem;
        margin-top: 20px;
    }

    .complementy-strap {
        font-size: 0.875rem;
        text-transform: uppercase;
        margin-top: 20px;
        font-weight: 600;
    }

    .starp-short-dec,
    .complementy-strap {
        line-height: 20px;
        letter-spacing: 0.5px;
        color: $color-black;
        @include media-query(small-up) {
            line-height: 22px;
        }
    }

    .product-detail {
        font-size: 1rem;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: $color-black; 
        text-decoration: underline;
        @include media-query(small-up) {
            margin-top: 0.938rem;
        }
    }
    .strap-carousel-wrapper {
        @include media-query(small-up) {
            padding: 0 20px;
        }
    }

    .pdp-straps {
        &__carousel {
            max-width: 80%;
            margin: 0 auto;
            @include media-query(small-up) {
                max-width: 100%;
            }

            .pdp-straps__item-image-wrapper {
                background-color: $color-grey-3;
                transition: .4s;
                max-width: 100%;
                height: 84px;
                position: relative;
                overflow: hidden;
                
                @include media-query(small-up) {
                    max-width: 4.1rem;
                }
            }

            .pdp-straps__item-image {
                transition: .4s;
                font-size: 0.5rem;
                max-width: initial;
                transform: translate(-50%, 0) rotate(-90deg);
                position: absolute;
                left: 50%;
                top: 160%;
                
                &.strap-DEFAULT {
                    position: absolute;
                    left: 50%;
                    transform: none;
                    top: 50%;
                    margin: 0 auto;
                    max-width: 100%;
                    transform: translate(-50%, -50%) scale(1.6);
                }
            }

            .slick-next {
                right: -10px;
            }

            .slick-prev {
                left:-10px;
            }

            .slick-arrow {
                width: 1.375rem;
                height: 1.375rem;
                top: 60%;
                background-color: transparent;
            }

            .starpcolor-name  {
                opacity: 0;
                display: none;
            }

            .pdp-straps__item {
                &.toggle {
                    .pdp-straps__item-image-wrapper {
                        border-bottom: 4px solid $color-red;
                    }
                }
                &[data-strap-instock=false] {
                    opacity: .5;
                    &[data-default-strap=true] {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .product-tile {
        &__body-section {
            .price {
                .tile-label {
                    display: none;
                }

                .price__sales {
                    line-height: 16.8px;
                    font-weight: 600;
                }
            }
        }
    }
    .price-detail-wrapper{
        @include media-query(small) {
            display: flex;
            justify-content: space-between;
            margin-top: 1.563rem;
        }
    }
}

.product-tile.index {
	.product-tile__wishlist {
		visibility: visible;
	}
}

@include media-query(small) {
    .strap-mobile.product-tile__wishlist {
        display: block;
    }
    .product-tile.strap-view-active {
        .strap__wishlist {
            z-index: 3;
        }
    }
    .product-tile.index {
        .strap__wishlist {
            top: 10px;
        }
    }    
}