
.footer-links__list--unstacked {
    display: flex;
    align-items: center;
    flex-flow: wrap;
}

@include media-query(small-up) {
    .footer-links {
        &:not(:last-child) {
            margin-bottom: 2.75rem;
        }
    }

        .footer-links__heading {
            margin-bottom: 1.75em;
        }

        .footer-links__list--stacked {
            .footer-links__item {
                &:not(:last-child) {
                    margin-bottom: .7em;
                }
            }
        }

        .footer-links__list--unstacked {
            margin-top: -.75rem;
            margin-bottom: -.75rem;

            .footer-links__item {
                margin-top: .75rem;
                margin-bottom: .75rem;

                &:not(:last-child) {
                    margin-right: 2em;
                }
            }
        }
}

@include media-query(small-up-to-medium) {
    .footer-links-col {
        &:not(:last-child) {
            margin-bottom:  2.25rem;
        }
    }
}

@include media-query(small) {
    .footer-links__list--unstacked {
        justify-content: center;
    }

    .footer-links,
    .footer__info-group {
        &:not(:first-child) {
            // margin-top: 2.75rem;
        }
    }

        .footer-links__heading--toggleable {
            padding-top: 1.35em;
            padding-bottom: 1.35em;
        }

            .footer-links__heading-arrow {
                position: relative;
                transition: transform $transition-speed-fast $transition-ease-bezier;

                .toggle--active & {
                    transform: rotate(180deg);
                }
            }

        .footer-links__list {
            margin-top: 1rem;
            margin-bottom: 1.8rem;
        }

        .footer-links__list--stacked {
            .footer-links__item {
                &:not(:last-child) {
                    margin-bottom: 1.65em;
                }
            }

            &:not(.toggle--active) {
                display: none;
            }
        }

        .footer-links__list--unstacked {
            .footer-links__item {
                &:not(:last-child) {
                    margin-right: 2.25em;
                }
            }
        }
}
