.breadcrumbs__item {
    &:not(:last-child) {
        margin-right: .45em;
    }
}

.breadcrumbs__separator {
    display: inline-block;
    margin-left: .45em;
}

@include media-query(small) {
    .breadcrumbs--spaced {
        margin-bottom: 1.5rem;
    }
}