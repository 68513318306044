
.media-collection-item {

}

    .media-collection-item__description {
        margin-top: .875em;
    }

    .media-collection-item__actions {
        margin-right: -.25rem;
        margin-left: -.25rem;
    }

        .media-collection-item__cta {
            margin-right: .25rem;
            margin-left: .25rem;

            &:not(:last-child) {
                margin-bottom: .75rem
            }
        }

@include media-query(small-up) {
    .media-collection__col {
        &:nth-child(n + 3) {
            margin-top: 2rem;
        }
    }

    .media-collection-item__content-wrap {
        padding: 3.5rem 1.5rem;
    }

    .media-collection-item__actions {
        margin-top: 2.125rem;
    }

    .media-collection-item__product-list {
        &:not(:first-child) {
            margin-top: 3.125rem;
        }
    }
}

@include media-query(small) {
    .media-collection__col {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }

    .media-collection-item__content-wrap {
        padding: 1.5rem 1rem;
    }

    .media-collection-item__actions {
        margin-top: 1rem;
    }

    .media-collection-item__product-list {
        &:not(:first-child) {
            margin-top: 2.25rem;
        }
    }
}
