
.content-tile {
    position: relative;

    // &:hover {
    //     .content-tile__title,
    //     .content-tile__description {
    //         opacity: .75;
    //     }
    // }
}

.content-tile__aspect-ratio {
    position: relative;
    overflow: hidden;
}

.content-tile__aspect-ratio--natural {
    //Fixes IE11 issue which renders overly tall elements if the image is large enough.
    flex-shrink: 0;
}

.content-tile--ratio-inherit {
    flex-grow: 1;
}

    .content-tile__aspect-ratio--inherit {
        min-height: 100%;
        flex-grow: 1;

        @include aspect-ratio(320, 224, true);
    }

    .content-tile__aspect-ratio--square {
        @include aspect-ratio(360, 375, true);
    }

    .content-tile__aspect-ratio--wide {
        @include aspect-ratio(7, 5, true);
    }

    .content-tile__aspect-ratio--tall {
        @include aspect-ratio(5, 7, true);
    }

    .content-tile__title,
    .content-tile__description {
        transition: opacity $transition-speed-faster $transition-ease-default;
    }

    .content-tile__content-wrap {
        &.set--text-after,
        &.set--text-after-large {
            padding-top: 1.6rem;
            padding-bottom: 1.6rem;

            &.set--has-background {
                padding-right: 2rem;
                padding-left: 2rem;
            }
        }

        &.set--text-before,
        &.set--text-before-large {
            padding-bottom: 1.6rem;
            padding-top: 1.6rem;

            &.set--has-background {
                padding-right: 2rem;
                padding-left: 2rem;
            }
        }
    }

    .content-tile__actions {
        margin-right: -.65rem;
        margin-left: -.65rem;
    }

        .content-tile__cta {
            margin-right: .65rem;
            margin-left: .65rem;

            &:not(:last-child) {
                margin-bottom: .75rem
            }
        }

@include media-query(small-up) {
    .content-tile--style-default {
        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-right: 2rem;
                padding-left: 2rem;
            }
        }

        .content-tile__description {
            margin-top: .875em;
        }

        .content-tile__actions:not(:first-child) {
            margin-top: .875rem
        }
    }

        .content-tile__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 1.875rem;
                padding-bottom: 1.875rem;
            }
        }
}

@include media-query(small) {
    .content-tile__content-wrap {
        &.set--text-overlay,
        &.set--text-overlay-small {
            padding: 1.35rem;
        }
    }

    .content-tile__description {
        margin-top: .35rem;
    }

    .content-tile__actions:not(:first-child) {
        margin-top: .825rem;
    }
}

.component-v-align--small-bottom {
    &.content-tile__content-wrap {
        &.set--text-after {
            padding-bottom: 0;
            padding-top: 1rem;
        }
    }
}
