

.html-block__media + .html-block__copy {
    &.set--text-after {
        margin-top: 2rem;
    }

    &.set--text-before {
        margin-bottom: 2rem;
    }
}

.html-block__title {
    &:not(:last-child) {
        margin-bottom: .5em;
    }
}

.html-block__actions {
    &:not(:only-child) {
        margin-top: 1.25em;
    }

    margin-right: -.5rem;
    margin-left: -.5rem;
}

    .html-block__cta {
        margin-right: .5rem;
        margin-left: .5rem;

        &:not(:last-child) {
            margin-bottom: 1rem
        }
    }

@include media-query(small-up) {
    .html-block,
    .html-block-list__item {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }

        .html-block__copy:not(:only-child) {
            &.set--text-after-large {
                margin-top: 2rem;
            }

            &.set--text-before-large {
                margin-bottom: 2rem;
            }
        }
}

@include media-query(small) {
    .html-block,
    .html-block-list__item {
        &:not(:last-child) {
            margin-bottom: 3rem;
        }
    }

        .html-block__copy:not(:only-child) {
            &.set--text-after-small {
                margin-top: 2rem;
            }

            &.set--text-before-small {
                margin-bottom: 2rem;
            }
        }
}

.blockScroll {
    margin-right: 15px;
    body {
        overflow: hidden;
    }
}