
.store-locator__head {
    margin-bottom: 1.25rem;
}

@include media-query(small-up) {
    .store-locator {
        padding-top: 4rem;
        padding-bottom: 8rem;
    }
}

@include media-query(small) {
    .store-locator {
        padding-top: 3rem;
        padding-bottom: 4rem;
    }
}