
.header-language-selector {

}

    .header-language-selector__selectable {
        opacity: .5;
    }

@include media-query(small-up) {
    .header-language-selector {
        position: relative;
        display: inline-block;
    }
}

@include media-query(small) {
    .header-language-selector {
        position: relative;
        margin-left: 4rem;
    }
}
