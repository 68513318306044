
//Force hide the <isapplepay> tag.
isapplepay {
    display: none !important;
}

.apple-pay-instant-checkout-disabled {
    button.applepay-checkout {
        background-color: #f0f0f0;
        border-color: #f0f0f0;
        color: #8c8c8c;
        cursor: not-allowed;
    }
}

.apple-pay-instant-checkout-disabled {
    .button--apple-pay {
        background-color: #8c8c8c;
        border-color: #f0f0f0;
        color: #8c8c8c;
        cursor: not-allowed;
    }
}

.apple-pay-button--wrapper {
    width: 100%;
}

.apple-pay-api-button {
    display: none;
}

.button {
    &.applepay-api--button{
        background-color: black;
        background-image: -webkit-named-image(apple-pay-logo-white);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 75% 60%;
        box-sizing: border-box;
        margin: 0.5rem auto;
        min-height: 38px;
        min-width: 100px;
        padding: 0;
        &:hover:not([disabled]):not(.disabled),
        &:focus:not([disabled]):not(.disabled),
        &:active:not([disabled]):not(.disabled) {
            background-color: black;
            background-image: -webkit-named-image(apple-pay-logo-white);
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }
    }
}