
.descriptive-card {
    position: relative;
}

.descriptive-card--style-default {

}

.descriptive-card--style-mini {
    .descriptive-card__media {
        @include aspect-ratio(1, 1, true);
    }

        // .descriptive-card__img {
        //     transition: transform $transition-speed-fast $transition-ease-bezier;
        // }

    .descriptive-card__content-wrap {
        padding: 1rem;
    }

    // &:hover {
    //     .descriptive-card__img {
    //         transform: scale(1.1) rotate(-5deg);
    //     }
    // }
}

.descriptive-card__aspect-ratio--square {
    @include aspect-ratio(1, 1, true);
}

    .descriptive-card__description {
        margin-top: .875em;
    }

    .descriptive-card__actions {
        margin-right: -.65rem;
        margin-left: -.65rem;

        &:not(:first-child) {
            margin-top: .875rem;
        }
    }

        .descriptive-card__cta {
            display: inline-block;
            margin-right: .65rem;
            margin-left: .65rem;

            &:not(:last-child) {
                margin-bottom: 1.5rem
            }
        }

@include media-query(small-up) {
    .descriptive-card {
        &:not(:last-child) {
            margin-bottom: 5rem;
        }
    }

        .descriptive-card__content-wrap {
            padding-top: 1.875rem;
            padding-bottom: 1.875rem;
        }

    .descriptive-card--style-default {
        .descriptive-card__aspect-ratio--wide {
            @include aspect-ratio(774, 474, true);
        }

        .descriptive-card__content-wrap {
            padding-right: 2.5rem;
            padding-left: 2.5rem;
        }
    }

    .descriptive-card--style-slim {
        .descriptive-card__aspect-ratio--wide {
            @include aspect-ratio(720, 300, true);
        }

        .descriptive-card__content-wrap {
            padding-right: 2rem;
            padding-left: 2rem;
        }
    }

    .descriptive-card--style-mini {
        padding-left: .5rem;
    }
}

@include media-query(small) {
    .descriptive-card {
        &:not(:last-child) {
            margin-bottom: 2.75rem;
        }
    }

    .descriptive-card--style-default,
    .descriptive-card--style-slim {
        .descriptive-card__aspect-ratio--wide {
            @include aspect-ratio(320, 200, true);
        }

        .descriptive-card__content-wrap {
            padding: 1.625rem 1rem;
        }
    }
}

.heading-type {
    .line-breaker {
        display: block;
    }
    svg {
        height: 18px;
        margin: 4px auto;
        vertical-align: bottom;
        @include media-query(small-up) {
            height: 30px;
            margin: 8px 0 12px 2px;
        }
    }
}

.read-more-btn {
    display: none;
}
