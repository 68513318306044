.price {
    .strike-through {
        color: $color-grey-5;
        font-size: $font-size-centi;
    }

    .price__original {
        margin-right: .5em;
    }

    .starting,
    .range,
    .sales {
        // font-weight: $font-weight-semibold;
    }

    .tiered {
        font-size: $font-size-base;
        line-height: $font-line-height-hecto;
    }
}
