@import 'propertyMixins';

/*------------------------------------*\
  #TYPOGRAPHY
\*------------------------------------*/

// Font families
$font-family-arabic: "Almarai";
$font-family-sans   : "Brilliant Cut", $font-family-arabic, Helvetica, Arial, sans-serif;
$font-family-sans-secondary: $font-family-sans;
$font-family-serif  : "Fancy Cut", $font-family-arabic, Times, serif;
$font-family-cursive: "Kuenstler Script", cursive;
$font-family-cartier-logos: "CartierLogos", $font-family-sans;

$font-family-script-412: "Script-412", $font-family-sans;
$font-family-sackers: "Sackers", $font-family-sans;
$font-family-celeste: "Celeste", $font-family-sans;
$font-family-Avant: "Avant Garde",Times,serif;
$font-family-fancy-cut: "Fancy Cut", Times ,serif;
$font-family-videojs: "VideoJS";

// Larger than headings
$font-size-yotta  : 8.875rem;  // 142px
$font-size-zetta  : 7.375rem;  // 118px
$font-size-exa    : 6.25rem;   // 100px

// Headings
$font-size-h1      : 5.125rem;  // 82px
$font-size-h2      : 4.375rem;  // 70px
$font-size-h3      : 3.5rem;    // 56px
$font-size-h4      : 3rem;      // 48px
$font-size-h5      : 2.5rem;    // 40px
$font-size-h6      : 1.875rem;  // 30px

// Body font sizes
// $font-size-tera    : 1.75rem; //28px
// $font-size-giga   : 1.5rem; //24px
// $font-size-mega    : 1.125rem; //20px
$font-size-kilo    : 1.625rem; //26px
$font-size-hecto   : 1.375rem; //22px
$font-size-deka    : 1.125rem; //18px

$font-size-base    : 1rem; // 16px Base font size

$font-size-deci    : .875rem; // 14px
$font-size-centi   : .75rem; // 12px
$font-size-micro   : .625rem; // 10px


// Font Weights
$font-weight-light    : 300;
$font-weight-normal   : 400;
$font-weight-semibold : 600;
$font-weight-bold     : 700;
$font-weight-extrabold: 800;

// Font Line Height
$font-line-height-mega  : 1.9;
$font-line-height-kilo  : 1.75;
$font-line-height-hecto : 1.65;
$font-line-height-deka  : 1.5;
$font-line-height-base  : 1.3;
$font-line-height-deci  : 1.25;
$font-line-height-centi : 1;
$font-line-height-milli : .75;


$font-letter-spacing-hecto: .05rem;
$font-letter-spacing-base: .03125rem;
$font-letter-spacing-deci: .025rem;

/*------------------------------------*\
  #COLORS
\*------------------------------------*/

$color-white: #FFF;
$color-black: #000;

//GREYSCALE COLORS
$color-grey-1: #F9F9F9;
$color-grey-2: #F0F0F0;
$color-grey-3: #E6E6E6;
$color-grey-4: #CCCCCC;
$color-grey-5: #8C8C8C;
$color-grey-6: #676767;
$color-grey-7: #1D1C1C;
$color-grey-8: #EAEAEA;

$color-red: #e60000;
$color-red-1:#C10016;
$color-blue: #4D96E7;
$color-green: #569f18;
$color-yellow: #E7B04D;
$color-beige: #f5f5dc;


//SOCIAL MEDIA COLORS
$color-facebook-blue: #3b5998;
$color-google-blue: #4285F4;
$color-google-red: #DB4437;
$color-instagram-pink: #C62A81;
$color-instagram-orange: #F06430;

$color-paypal-blue: #009cde;
$color-paypal-silver: #eeeeee;

$color-primary: $color-grey-7;
$color-secondary: #D50032;
$color-tertiary: #5A5A5A;

$color-accent-primary: darken($color-primary, 10%);
$color-accent-secondary: darken($color-secondary, 10%);
$color-accent-tertiary: darken($color-tertiary, 10%);

$colors: (
    white: $color-white,
    black: $color-black,

    //GREYSCALE COLORS
    grey-1: $color-grey-1,
    grey-2: $color-grey-2,
    grey-3: $color-grey-3,
    grey-4: $color-grey-4,
    grey-5: $color-grey-5,
    grey-6: $color-grey-6,
    grey-7: $color-grey-7,

    red: $color-red,
    blue: $color-blue,
    navy: darken($color-blue, 35%),
    green: $color-green,
    beige: $color-beige,

    //SOCIAL MEDIA COLORS
    facebook-blue: $color-facebook-blue,
    google-blue: $color-google-blue,
    google-red: $color-google-red,
    instagram-pink: $color-instagram-pink,
    instagram-orange: $color-instagram-orange,

    paypal-blue: $color-paypal-blue,
    paypal-silver: $color-paypal-silver,

    primary: $color-primary,
    secondary: $color-secondary,
    tertiary: $color-tertiary,

    accent-primary: $color-accent-primary,
    accent-secondary: $color-accent-secondary,
    accent-tertiary: $color-accent-tertiary,

    error: $color-red,
    success: $color-green
);

//UI COLORS
$color-error: $color-red;
$color-success: $color-green;

$color-body-copy: $color-grey-7;
$color-body-background: $color-white;
$color-panel-background: $color-body-background;
$color-input-background: transparent;

/*------------------------------------*\
  #ELEMENT-DEFAULTS
\*------------------------------------*/
$input-border-radius: 0;

/*------------------------------------*\
  #HEADER-DEFAULTS
\*------------------------------------*/
$header-nav-bg: var(--header-nav-bg, $color-body-background);
$header-nav-color: var(--header-nav-color, 'currentColor');
$header-nav-panel-bg: var(--header-nav-panel-bg, $color-body-background);
$header-nav-panel-color: var(--header-nav-panel-color, 'currentColor');

/*------------------------------------*\
  #HORIZONTAL-SPACING
\*------------------------------------*/

$gutter-inner: .75rem; //base
$gutter-inner-deci: .46875rem;

$gutter-outer-xlarge: 96px;
$gutter-outer-kilo: 5.875rem;
$gutter-outer-hecto: 4rem;
$gutter-outer: 2rem; //base.
$gutter-outer-deci: 1.5rem;
$gutter-outer-centi: .9375rem;
$gutter-outer-micro: .75rem;

/*------------------------------------*\
  #BREAKPOINTS
\*------------------------------------*/

$breakpoints: (
    unit           : 0.0625rem, // 1
    xxsmall        : 20rem, // 320
    xsmall         : 30rem, // 480
    small          : 47.9375rem, // 767
    medium-down    : 63.9375rem, // 1,023
    medium         : 64rem, // 1,024
    xmedium        : 77.5rem, // 1,240
    large          : 90rem // 1,440
);


/*------------------------------------*\
  #TRANSITIONS
\*------------------------------------*/

// Transition durations
// **** If this is updated, make sure FEDConstants.js is also updated. ***
$transition-speed-slower    :   1s;
$transition-speed-slow      :   750ms;
$transition-speed-mid-slow  :   600ms;
$transition-speed-normal    :   500ms;
$transition-speed-medium    :   400ms;
$transition-speed-fast      :   300ms;
$transition-speed-faster    :   200ms;
$transition-speed-fastest   :   100ms;

// Transition easing
$transition-ease-default    :   ease-in-out;
$transition-ease-in         :   ease-in;
$transition-ease-out        :   ease-out;
$transition-ease-bezier     :   cubic-bezier(.4,.9,.3,1);
$transition-ease-bounce     :   cubic-bezier(.4,.9,.3,1.25);
$transition-ease-big-bounce :   cubic-bezier(.4,.9,.3,2.25);

$pdp-font-personalisation-block: inherit;
$pdp-font-personalisation-italic: $font-family-cursive;
$pdp-font-personalisation-combined: "Amiri";