
/*------------------------------------*\
  #COUNTRY SELECTOR
\*------------------------------------*/
.country-selector-page {
    padding-top: 4rem;
}

    .country-selector-page__title {
        margin-bottom: 2em;
    }

.country-selector__country {
    padding-left: 1.25rem;
    position: relative;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    .country-selector__cart-icon {
        margin-right: 0.625rem;
        width: 0.75rem;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.country-selector__country-list {
    margin-top: 1.35rem;
    line-height: $font-line-height-deka;
}

.country-selector__locale-division {
    margin-left: .35rem;
    margin-right: .35rem;
}

/*------------------------------------*\
  #GEO IP
\*------------------------------------*/
.country-geoip__logo {
    width: 10rem;
    height: 2.9rem;
}

.country-geoip__more-countries {
    &:not(.toggle--active) {
        display: none;
    }
}

.country-geoip__more-trigger {
    &.toggle--active {
        display: none;
    }
}

.country-geoip__main-actions {
    max-width: 50rem;
}

.country-geoip__more-countries {
    margin-top: 2rem;
}

@include media-query(small-up) {
    .country-selector__region {
        &:not(:last-child) {
            margin-bottom: 2.5rem;
        }
    }

    .country-selector__column-split {
        column-count: 2;
    }
}

@include media-query(small) {

    .country-selector__region,
    .country-selector__column {
        &:not(:last-child) {
            margin-bottom: 3.5rem;
        }
    }
    
    .country-selector__column {
    	&.flex-direction-row {
    		flex-direction: column;
            .country-selector__region {
                padding: 0;
            }
    	}
    }
}
