.store-locator__detect-location {
    margin-top: .5rem;
}

// .store-locator__result-check {
//     &:checked + .store-locator__result-content {
//         background-color: $color-grey-2;
//     }
// }

.store-locator__result-content {
    display: block;
    // padding: 1.25rem 1rem;

    &:hover {
        cursor: pointer;

        .store-details__name {
            text-decoration: underline;
        }
    }
}

.store-locator__form {
    margin: 2rem 0 3rem;
}

.store-locator__results,
.store-locator__results--extended {
    overflow: auto;
}

    .store-locator__result-item {
        position: relative;
        // border-bottom: solid .0625rem $color-grey-2;
    }

.store-locator__no-results {
    margin: auto;
}

.store-locator__actions {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

    .store-locator__result-item--extended {
        &:not(:last-child) {
            margin-bottom: 3.5rem;
        }
    }

        .store-details--extended {
            .store-details__name {
                margin-bottom: 1rem;
            }

            .store-details__section {
                &:not(:last-child) {
                    margin-bottom: 1.5rem;
                }
            }

            .store-details__availability-indicator {
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 1.25rem;
                margin-right: .5rem;
            }
        }

.store-locator--product-availability {
    .store-locator__contact-form {
        display: none;
        margin-bottom: 1.5rem;
        max-width: 23.5rem;
    }

    &.set--show-form {
        .store-locator__contact-form {
            display: block;
        }

        .store-details--extended:not(.set--show-form) {
            display: none;
        }

        .store-locator__query-section-wrapper {
            display: none;
        }
    }

    .store-details__hidden-actions {
        display: none;
        margin-bottom: 2rem;
    }

    .store-details__contact-description {
        margin-top: 3.25rem;
    }

    .store-details--extended.set--show-form {
        .store-details__check-availability {
            display: none;
        }

        .store-details__hidden-actions {
            display: block;
        }
    }

    .store-details__hours {
        flex: 1 1;
    }

        .store-details__hours-day {
            flex: 0 0 6.25rem;
        }
}

.store-locator--store-pickup {
    .store-details__name,
    .store-details__address {
        margin-bottom: 1rem;
    }

    .store-locator__result-details-cta {
        margin-top: .35rem;
        margin-left: 2.2rem;
    }

    .store-locator__result-item {
        &:not(:last-child) {
            margin-bottom: 3rem;
        }
    }
}

.store-details__delivery-value {
        margin-left: 1.5rem;
        &.heading-type {
            @include bidirectionalProperty(font-weight, $font-weight-semibold, $font-weight-semibold);    
        }
}

.store-details--extended-modal {
    .store-details__main {
        margin-bottom: 4rem;
    }

    .store-details__name {
        margin-bottom: .5rem;
    }

    .store-details__section {
        &:not(:last-child) {
            margin-bottom: 2.25rem;
        }
    }

        .store-details__section-title {
            margin-bottom: .65rem;
        }

        .store-details__hours-row {
            justify-content: space-between;

            &:not(:last-child) {
                margin-bottom: .5em;
                padding-bottom: .5em;
                border-bottom: solid .0625rem $color-grey-2;
            }
        }

            .store-details__hours-day {
                flex: 0 0 4.875rem;
            }
}

@include media-query(small-up) {
    .store-locator--product-availability {
        .store-locator__contents {
            padding-left: 2.5rem;
        }

        .store-details__action-cta {
            min-width: 15rem;
        }
    }

    .store-locator__results {
        max-height: 30rem;
    }

    .store-locator__results--extended {
        max-height: 65vh;
    }
}

@include media-query(small) {
    .store-locator--product-availability {
        .store-locator__contents {
            margin-top: 3.75rem;
        }
    }

    .store-locator--store-pickup {
        .store-locator__title {
            margin-bottom: 1.5rem;
        }

        .store-locator__form-group {
            &:not(:last-child) {
                margin-bottom: 1.75rem;
            }
        }

        .store-locator__no-results {
            text-align: center;
        }
    }

    .store-locator__results {
        max-height: 22rem;
    }
}
