@import "~core/components/formElements";

/*------------------------------------*\
  #PLACE OVERRIDES HERE
\*------------------------------------*/

.form-combo {
    // Ensure label is flush with labels above
    .form-control-label {
        margin-left: 0;
    }
}
