@import "~core/pages/storeLocator/storeLocatorComponents";

.pickup-in-store {

    .store-locator__result {
            
        &-content {

            &:hover {
                cursor: default;

                .store-details__name {
                    text-decoration: none;
                }
            }
        }
    }

    .store-details {
        
        &__title {
            margin: 20px 0;
            text-transform: none;
            font-weight: 100;
            border-bottom: 1px solid;
            display: inline-block;
            padding-bottom: 5px;
        }

        &__phone-number {
            font-weight: 100;
        }

        &__phone-title,
        &__opening-hours-title {
            margin-bottom: 10px;
        }

        &__opening-hours-title ~ div {
            font-weight: 100;
        }

        &__opening-hours-content {
            table {
                border:0;
                width: 82%;

                tr {
                    td:first-child {
                        text-transform: lowercase;
                        width: 41%;
                    }
                    .store-details__days:first-child {
                        text-transform: capitalize;
                    }
                }

                td {
                    border: 0;
                    border-bottom: 1px solid #ccc;
                    padding: 10px 0;
                }
            }
        }
    }
}

@include media-query(small-up) {

    .pickup-in-store {

        .store-locator__results {
            max-height: 100%;
        }
    }
}