
.product-line-item {
    position: relative;

    // &:not(:last-child) {
    //     border-bottom: solid .0625rem $color-grey-3;
    // }

    &.set--in-wishlist {
        .product-line-item__wishlist-add {
            display: none;
        }

        .product-line-item__wishlist-remove {
            display: inline-block;
        }
    }
    
    &__add-strap-product-true {
        overflow: hidden;
        
        .product-line-item__image {
            transform: rotate(-90deg) scale(2);
            top: 110%;
        }
    }
}

.product-line-item--bonusStrap {
    margin-top: 3.125rem;
    min-height: 6rem;
    .product-line-item__actions {
        margin: 0 0 0 1rem;
        @include media-query(small-up) {
            margin: 0 1.25rem;
        }
    }
    .product-line-item__image {
        width: 60%;
        transform: rotate(270deg);
        margin: 0 auto;
        object-position: right;
    }
}

.product-line-item--cart,
.product-line-item--minicart {
    .product-line-item__action-cta--remove {
        position: relative;
        top: -.25rem;
        margin-left: 1.25rem;
    }
}

.product-line-item--cart {
    background-color: $color-grey-1;

    &:not(:last-child), &:first-child {
        margin-bottom: 1.875rem;
    }

    .product-line-item__personalisation {
        &:not(:first-child) {
            margin-top: .25rem;
        }
        .item-attributes {
            margin-bottom: .5rem;
        }
    }
}

.product-line-item--wishlist {
    &.set--removed-wishlist {
        .product-line-item__wishlist-revert {
            display: block;
        }

        .product-line-item__main {
            display: none;
        }
    }
}

.product-line-item--order {
    // border-top: solid .0625rem $color-grey-3;
}

.product-line-item--shipment {
    margin-bottom: 1.25rem;
    padding-bottom: 1.5rem;
    border-bottom: dashed .0625rem $color-grey-2;
}

    .product-line-item__media-link {
        display: block;
    }

    .product-line-item__image-wrap {
        @include aspect-ratio(1, 1, true);
        background-color: $color-grey-1;
    }

        .product-line-item__image {
            width: 70%;
            height: 70%;
            &.full-width {
                width: 100%;
                height: 100%;
            }
        }

    .product-line-item__header {
        margin-bottom: .45em;
    }

    .product-line-item__name {
        text-transform: uppercase;
        font-size: $font-size-deci;
    }

    .select_exchange--reason {
        margin: 0.5rem 0;
    }

    .product-line-item__footer {
        margin-top: 1.25rem;
    }

    .product-line-item__attribute,
    .product-line-item__attributes {
        &:not(:last-child) {
            margin-bottom: .45em;
        }
        &.additional-strap {
            .product-line-item__actions {
                margin-left: 1.125rem;
            }
        }
    }

    .product-line-item__actions {
        display: flex;
        flex-wrap: wrap;
    }
    .product-line-item__action--additionalStrap {
        margin-bottom: .45em;
    }

        .product-line-item__action {
            &:not(:last-child) {
                margin-right: 1.25rem;
            }
        }

    .product-line-item__qty-input {
        max-width: 5rem;
        margin-left: 1rem;
    }

    .product-line-item__wishlist-remove {
        display: none;
    }

    .product-line-item__wishlist-revert {
        display: none;
    }

    .product-line-item__pickup {
        margin-top: 1rem;
    }

/*------------------------------------*\
  #BUNDLE PRODUCTS
\*------------------------------------*/

.bundled-list {
    position: relative;
    margin-top: 1.25rem;
    border-top: dashed .0625rem $color-grey-2;
}

    .bundled-list__title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 10rem;
        margin: auto;
        transform: translateY(-55%);
    }

    .bundled-list-item {
        margin-top: 1.25rem;
    }

        .bundled-list-item__media {
            margin-right: .25rem;
        }


@include media-query(medium-up) {
    .product-line-item--order,
    .product-line-item--minicart,
    .product-line-item--wishlist {
        .product-line-item__qty-pricing {
            margin-top: 1.25rem;
        }
    }

    .product-line-item--cart {
        .product-line-item__unit-price,
        .product-line-item__total-price {
            margin-left: 1rem;
        }

        .price {
            justify-content: flex-end;
        }

            //Intentionally reset spacing for the product-line-item in cart.
            .price__original {
                margin-right: 0;
            }

            .price__sales {
                margin-left: .5rem;
            }
    }
}

@include media-query(medium) {
    .product-line-item__qty-pricing {
        margin-top: 1rem;
    }
}

@include media-query(small-up) {
    .product-line-item {

    }

    .product-line-item--cart {
        padding-top: 2.5rem;
        padding-bottom: 2.25rem;

        .product-line-item__header-price {
            margin-left: auto;
        }

        .product-line-item__actions {
            margin-top: auto;
        }

        .product-line-item__personalisations {
            margin-top: 1rem;
        }

            .product-line-item__personalisation-actions {
                margin-top: .25rem;
                margin-bottom: 1rem;
            }
    }

    .product-line-item--minicart,
    .product-line-item--order {
        padding-top: 1.875rem;
    }

    .product-line-item--minicart {
        padding-right: 3.375rem;
        padding-left: 3.375rem;

        .product-line-item__action-cta--remove {
            right: -.65rem;
        }
    }

        .product-line-item__action {
            &:not(:last-child) {
                margin-bottom: .45em;
            }
        }

        .product-line-item__qty-form {
            margin-top: 1.5rem;
        }
}

@include media-query(small) {
    .product-line-item {
        padding-top: 1.875rem;
        padding-bottom: 1.875rem;
    }

    .product-line-item--minicart {
        padding-right: 1.25rem;
        padding-left: 1.25rem;
    }

    .product-line-item--cart {
        .product-line-item__personalisation-actions {
            margin-top: .75rem;
            margin-bottom: .5rem;
        }
    }


        .product-line-item__action,
        .product-line-item__qty-form {
            margin-bottom: 1rem;
        }

        .product-line-item__add {
            flex: 1 0 100%;
        }
}

.product-line-item__giftmessage {
    .product-line-item__attribute {
        word-break: break-word;
    }
}

.product-line-item__embossing {
    .heading-type.body-type--centi,
    .emboss-text {
        font-family: $font-family-sans;
        font-size: 0.75rem;
        font-weight: 400;
        text-transform: uppercase;
    }
}
