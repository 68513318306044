/**
 * Contains global styles for the header.
 * This stylesheet should not include component specific styles.
 */

.header-container {
    .set--header-blend & {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    }

    &.set--overlay {
        .header-promo {
            z-index: 1;
        }
    }

    &:not(.set--overlay) {
        .header-promo {
            transition: z-index 0s $transition-ease-default $transition-speed-fast;
        }
    }
}

.header {
    z-index: 5;
    position: relative;
    background-color: $header-nav-bg;
    transition-property: background-color, color, box-shadow;
    transition-duration: $transition-speed-fast;
    transition-timing-function: $transition-ease-out;
    cursor: initial;

    &:not(.header--no-menu) {
        box-shadow: 0 .0625rem 0 0 transparentize($color-black, .85);
    }

    .set--header-blend & {
        &:not(:hover):not(:focus-within):not(.fixit--scroll-direction-change):not(.focus-within) {
            background-color: transparent;
            color: $color-white;
            box-shadow: inset 0 -.0625rem 0 0 transparent;
            transition-timing-function: $transition-ease-in;
        }
    }

    &.fixit--active {
        position: absolute;
        width: 100%;
        //Intentionally override transition properties here to prevent jumping when header becomes un-fixed.
        transition-property: margin, background-color, color, box-shadow;
    }

    .fixit--active {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: #fff;
        margin: 0;
    }

    //Use FixIt's state classes to only display the navigation when scrolling up.
    //Separate margin-top is used on desktop and mobile for the hidden state.
    //***IF THIS PROPERTY CHANGES, ENSURE IT IS ALSO CHANGED IN miniCart.js `openMinicart()` METHOD.***
    &.fixit--scrolled {
        &.fixit--scroll-up {
            // transform: translateY(0);
            margin-top: 0;
        }
    }
}

    .header__logo,
    .header__hamburger-trigger  {
        color: $header-nav-color;
    }

    .header__logo {
        //Ensures width is maintained on older browsers (IE11).
        flex-grow: 1;
    }

        .header__logo-img {
            width: 100%;
            height: 100%;
            fill: currentColor;
        }

        .header__logo-copy {
            margin-top: 0.45em;
        }

    .header__skip-to-main {
        position: absolute;
        top: 1rem;
        left: 2rem;
        z-index: 1000;

        &:not(:focus) {
            pointer-events: none;
            opacity: 0;
        }
    }

.header-promo {
    position: relative;
    //Prevent other contents on the page from overlapping this element.
    z-index: 5;

    &.toggle--active {
        display: none;
    }
}

    .header-promo__close-form {
        position: absolute;
        top: 0;
        bottom: 0;
    }

        .header-promo__close-button {
            height: 1.75rem;
            width: 1.75rem;
        }

@include media-query(large-up) {
    //Special positioning so that it stays within the site's "max width".
    .header-promo__close-form {
        left: calc(50% + #{map-get($breakpoints, large) / 2} - 1.7rem);
        transform: translateX(-100%);
    }
}

@include media-query(medium-up-to-large) {
    .header-promo__close-form {
        right: 1.7rem;
    }
}

@include media-query(small-up-to-medium) {
    .header-promo__close-form {
        right: 1.25rem;
    }
}

@include media-query(small-up) {
    .header {
        //Use FixIt's state classes to hide the navigation when scrolling down.
        &.fixit--scrolled {
            &.fixit--scroll-down:not(:hover):not(:focus-within):not(.focus-within) {
                //Avoid using transform to prevent some funky issues with display.
                //This is a small sacrifice, but the header will always have a standard height anyway.
                //Adjust if necessary (separate height is used in mobile).
                // transform: translateY(-100%);
                margin-top: 0;
            }
        }
    }

    .header--no-menu {
        padding-top: 2.5rem;
    }

    .header__main {
        align-items: flex-start;
        padding-top: 1.5rem;
    }

        .header__main-nav {
            margin-top: .95rem;
        }

    .header__logo {
        max-width: 10rem;
        height: 2.85rem;

        .header--logo-has-copy & {
            height: 4.15rem;
        }
    }
}

@include media-query(small) {
    .header {
        z-index: 11;
        //Use FixIt's state classes to hide the navigation when scrolling down.
        &.fixit--scrolled {
            &.fixit--scroll-down:not(:hover):not(:focus-within):not(.focus-within) {
                //Avoid using transform to prevent some funky issues with display.
                //This is a small sacrifice, but the header will always have a standard height anyway.
                //Adjust if necessary (separate height is used in mobile).
                // transform: translateY(-100%);
                margin-top: -5rem;
            }
        }
    }

    .header--no-menu {
        padding-top: 1.5rem;
    }

    .header__main {
        align-items: center;

        .header--logo-has-copy & {
            padding-top: .6rem;
            padding-bottom: .6rem;
        }

        .header--logo-no-copy & {
            padding-top: 1.325rem;
            padding-bottom: 1.325rem;
        }
    }

    .header__logo {
        max-width: 6.8rem;
        height: 1.95rem;
        margin: auto;

        .header--logo-has-copy & {
            height: 3.45rem;
        }
    }

    .header-promo__close-form {
        right: .7rem;
    }
}
