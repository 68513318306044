
.hero,
.hero__aspect-ratio {
    //Ensure relative position on viewports that might not use `aspect-ratio`.
    position: relative;
}

    .hero__subtitle {
        margin-bottom: .25em;
    }

    .hero__description {
        margin-top: 1em;
    }

    .hero__actions {
        margin-top: 1rem;
        margin-right: -.5rem;
        margin-left: -.5rem;
    }

        .hero__cta {
            display: inline-block;
            margin-right: .5rem;
            margin-left: .5rem;

            &:not(:last-child) {
                margin-bottom: 1.25rem
            }
        }

@include media-query(large-up) {
    .hero--viewport {
        .hero__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 9rem;
                padding-bottom: 9rem;
            }
        }
    }

    //Hacky way to force Slick to respect the Hero's height when in a carousel.
    //This is necessary since Slick adds height: 100%; and min-height: 1px; to slide items.
    .hero__aspect-ratio {
        &:before {
            display: block;
            content: '';
        }
    }
}

@include media-query(small-up-to-large) {
    .hero--viewport {
        .hero__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 10vh;
                padding-bottom: 10vh;
            }
        }
    }
}

@include media-query(small-up) {
    .hero__aspect-ratio--main {
        @include aspect-ratio(1440, 560, true);
    }

    .hero__aspect-ratio--slim {
        @include aspect-ratio(1440, 416, true);
    }

    .hero__aspect-ratio--hairline {
        @include aspect-ratio(1440, 300, true);
    }

    .hero {
        .hero__content-wrap {
            &.set--text-after,
            &.set--text-after-large {
                padding-top: 2.25rem;
                padding-bottom: 2.25rem;
            }

            &.set--text-before,
            &.set--text-before-large {
                padding-top: 2.25rem;
                padding-bottom: 2.25rem;
            }
        }
    }
    
    .hero--viewport,
    .hero--main,
    .hero--natural {
        .hero__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 2.25rem;
                padding-bottom: 2.25rem;
            }
        }
    }

    .hero--fullscreen {
        .hero__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 2.25rem;
                padding-bottom: 3rem;
            }
        }
    }

    .sticku-menu-enabled {
        .hero--fullscreen {
            .hero__content-wrap {
                &.set--text-overlay,
                &.set--text-overlay-large {
                    padding-bottom: 6rem;
                }
            }
        }
    }

    .hero--slim {
        .hero__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
            }
        }
    }

    .hero--hairline {
        .hero__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
    }

    .hero-fullscreen__first-element {
        .hero__aspect-ratio--fullscreen {
            &:before {
                //Substract the desired header size from the viewport height.
                height: calc(100vh - var(--header-floating-height));
            }
        }
    }

    .hero__aspect-ratio--fullscreen {
        &:before {
            display: block;
            //Substract the desired header size from the viewport height.
            height: calc(100vh - var(--header-fixed-height));
            min-height: 20rem;
            content: '';
        }
    }

    .hero__aspect-ratio--viewport {
        &:before {
            display: block;
            //Substract the desired header size from the viewport height.
            height: calc(100vh - 4.125rem);
            min-height: 27.5rem;
            content: '';
        }

        //.set--header-blend Is set in the ".page" element (pdStorePage.isml, page.isml, etc.).
        //This modifier class controls wether or not the header should be blend and transparent.
        //Whenever this class is present, the "viewport height" heroes should occupy the full viewport's height.
        .set--header-blend &:before {
            height: 100vh;
        }
    }

    .hero__description {
        max-width: 40rem;

        .text-align--center & {
            margin-left: auto;
            margin-right: auto;
        }

        .text-align--right & {
            margin-left: auto;
        }
    }
}

.hero__content-wrap {
    &.set--text-overlay {
        z-index: 3;
    }
}

.hero__aspect-ratio--fullscreen {
    overflow: hidden;
    .vjs-controls-disabled {
        .vjs-play-toggle {
            display: none;
        }
    }
}

@include media-query(small) {
    .hero__aspect-ratio--fullscreen {
        &:before {
            display: block;
            //Substract the desired header size from the viewport height.
            height: calc(100svh - var(--header-fixed-height));
            min-height: 16rem;
            content: '';
        }
    }

    .hero-fullscreen__first-element {
        .hero__aspect-ratio--fullscreen {
            &:before {
                //Substract the desired header size from the viewport height.
                height: calc(100svh - var(--header-floating-height));
            }
        }
    }

    .hero--fullscreen {
        .hero__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-bottom: 3rem;
            }
        }
    }

    .sticku-menu-enabled {
        .hero--fullscreen {
            .hero__content-wrap {
                &.set--text-overlay,
                &.set--text-overlay-large {
                    padding-bottom: 6rem;
                }
            }
        }
    }

    .hero__aspect-ratio--viewport {
        &:before {
            display: block;
            height: calc(100vh - 11rem);
            min-height: 16.25rem;
            content: '';
        }

        //.set--header-blend Is set in the ".page" element (pdStorePage.isml, page.isml, etc.).
        //This modifier class controls wether or not the header should be blend and transparent.
        //Whenever this class is present, the "viewport height" heroes should occupy the full viewport's height (minus the iOS nav-bar).
        .set--header-blend &:before {
            height: calc(100vh - 6rem);
        }
    }

    .hero__aspect-ratio--main {
        @include aspect-ratio(375, 450, true);
    }

    .hero__aspect-ratio--slim {
        @include aspect-ratio(375, 250, true);
    }

    .hero__aspect-ratio--hairline {
        @include aspect-ratio(375, 188, true);
    }

        .hero__content-wrap {
            padding-top: 2rem;
            padding-bottom: 2rem;

            .hero-carousel &.set--text-after,
            .hero-carousel &.set--text-after-small {
                padding-bottom: 4rem;
            }
        }

            .hero__cta {
                // min-width: calc(75% - 1rem);

                //Force full width whenever a PageDesigner CTA is rendered within a Module.
                .button {
                    width: 100%;
                }
            }
}

.scroll-dropdown {
    svg {
        display: block;
        height: 30px;
        position: absolute;
        bottom: 10px;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);
        @include media-query(small) {
            bottom: 7px;
        }
    }
}
