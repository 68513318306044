
.tab-nav {
    display: flex;
    position: relative;
    margin-bottom: 2rem;
    box-shadow: inset 0 -.0625rem 0 0 $color-grey-4;
}

    .tab-nav__item {
        padding-top: .5em;
        padding-bottom: .75em;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: .5;
        font-size: $font-size-deci;
        transition-property: box-shadow, color;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-bounce;
        cursor: pointer;
        opacity: .5;

        &:hover:not(.toggle--active),
        &:hover:not(.w-toggle--active) {
            opacity: 1;
        }

        //Only grow to use all available space when used within a .tab-nav container.
        .tab-nav & {
            flex: 1 1 50%;
            max-width: 50%;
        }

        &.toggle--active,
        &.w-toggle--active,
        .tab-nav__item-check.toggle--active + & {
            opacity: 1;
            box-shadow: inset 0 -.1875rem 0 0 $color-secondary;
        }
    }

    .tab-nav__item-check {
        position: absolute;
        opacity: 0;
    }

.tab-content {
    position: relative;
}

    .tab-content__panel {
        // transition-property: opacity, visibility;
        // transition-duration: $transition-speed-fast;
        // transition-timing-function: $transition-ease-default;

        // &:not(.toggle--active) {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;

        //     @include set-invisible();
        // }

        // &.toggle--active {
        //     @include set-visible();
        // }

        &:not(.toggle--active) {
            display: none;
        }
    }

    .watches-tab__container {
        @media screen and (max-width:768px) {
            padding: 0 1rem 2rem;
        }
    }

    .watches-specifications {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
        margin-bottom: 4rem;
        .watches-tab {
            display: inline-block;
            font-size: .75rem;
            font-weight: 400;
            margin: 0 16px;
            padding: 8px 0;
            position: relative;
            text-align: center;
            text-transform: uppercase;
        }
    }

    .watches-tab-list {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .watches-tab__section {
        &:not(.w-toggle--active) {
            display: none;
        }
        .pdp__product-spec {
            margin-top: 0;
            @media screen and (min-width:1024px) {
                display: grid;
                grid-auto-flow: column;
                grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
                grid-auto-columns: 1fr;
                grid-template-columns: 1fr;
                width: 100%;
            }
            .attribute {
                padding-top: 1.5rem;
                word-break: break-word;
            }
        }
    }

    .tab-nav-watch {
        width: auto;
        max-width: 100%;
        border-bottom: 1px solid #dfdfdf;
        display: inline-flex;
        position: relative;
    }
