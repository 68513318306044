
.product-attribute__list {
    //Remove bottom margin to offset the difference added by the individual
    //.product-attribute elements.
    margin-bottom: -1rem;

    //This specifity is intentional so that it is easier to separate
    //different types of "product-attribute__list" container.
    //For example, one may prefer to have a different layout for QuickAdd, Bundles, etc.
    .product-attribute {
        flex: 1 1 100%;
        margin-bottom: 1rem; 
    }

    .product-attribute--engrav,
    .product-attribute--emboss
    {
        margin-bottom: 0;
    }

    .product-attribute--color {
        margin-bottom: 2.5rem;

        .product-attribute__contents {
            margin-top: -1.5rem;
        }
    }

    .product-attribute--qty {
        flex-basis: 6.375rem;
        max-width: 6.375rem;
    }

        .product-attribute__swatch {
            flex: 0 0 5.5rem;
            width: 5.5rem;
            height: 5.5rem;
            margin-top: 1.5rem;
            margin-bottom: 1rem;

            &:not(:last-child) {
                margin-right: 1rem;
            }
        }

        .product-attribute__selected-value {
            display: block;
            margin-top: .5rem;
        }
}

.product-attribute__list--check-availability {
    margin-top: 2rem;

    .product-attribute__swatch {
        flex: 0 0 4rem;
        width: 4rem;
        height: 4rem;
    }
}

    .product-attribute__swatch {
        position: relative;

        &:after {
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 100%;
            box-shadow: 0 .25rem 0 $color-secondary;

            transition-property: width;
            transition-duration: $transition-speed-fast;
            transition-timing-function: $transition-ease-in;

            content: '';
        }

        &:not(.disabled):hover, &.selected {
            &:after {
                left: 0;
                width: 100%;

                transition-timing-function: $transition-ease-out;
            }
        }

        &.disabled, &.unselectable {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    .product-attribute__size {
        [data-lang="de"] & {
            text-transform: initial;
        }
    }

        .product-attribute__swatch-label {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            margin-top: .5rem;
            text-align: center;
        }

    .product-availability__label {
        margin-right: .25em;
    }

    .product-attribute__size-chart-trigger {
        margin-bottom: 1rem;

        .size-chart__anchor {
            cursor: pointer;
          }
    }

.product-gallery__img {
    max-height: none;
    width: 60%;
    height: 60%;
    &.full-stretch-image {
        width: 100%;
        height: 100%;
    }
}

.product-promotions__item {
    word-break: break-word;
}

// .product-pickup {
//     margin-top: 1rem;
// }

    .product-pickup__selected {
        padding-left: 1.9em;
        margin-top: .5rem;
    }

    .product-pickup__selected-action {
        margin-top: .75rem;
    }

.product-common__wishlist-trigger {
    width: 2.8125rem;
    height: 2.1875rem;
    margin-left: 1rem;
    border: solid .0625rem $color-primary;
    transition: color $transition-speed-normal $transition-ease-bezier,
                border-color $transition-speed-normal $transition-ease-bezier;

    &:hover, &:focus {
        color: $color-secondary;
    }

    &.set--in-wishlist {
        .product-common__wishlist-add {
            display: none;
        }

        .product-common__wishlist-remove {
            display: block;
        }
    }
}

    .product-common__wishlist-remove {
        display: none;
    }

.product-common__social {
    margin-top: 4rem;
}

    .product-common__social-item {
        &:not(:last-child) {
            margin-right: 2.5rem;
        }
    }

.product-add__container {
    flex-wrap: wrap;
    .product-get__notify {
        flex: 0 0 100%;
        margin-bottom: 1.125rem;
    }
    p {
        margin-bottom: 0.5rem;
    }
}

.product-add__limit {
    position: absolute;
    top: -1.3rem;
    width: 100%;
    text-align: center;
    font-size: small;
}

.brand__panther-image {
    &.image-width__small {
        img {
            max-width: 5rem;
            margin: 0 auto;
        }
    }
    &.image-width__large {
        img {
            max-width: 8.125rem;
            margin: 0 auto;
        }
    }
}

.pdp {
    .brand__panther-image {
        padding-bottom: 6rem;
    }
}

.search-results__footer {
    .brand__panther-image {
        padding-bottom: 0.938rem;
    }
}

.cart {
    .brand__panther-image {
        max-width: 77.5rem;
        margin: 0 auto;
        &.inverted {
            padding: 0 0.9375rem 1rem;
            img {
                transform: scaleX(-1);
                max-width: 3.75rem;
                margin: 0 auto 0 0;
            }
            @include media-query(small-up) {
                padding: 0 0 2rem;
                img {
                    max-width: 5rem;
                }
            }
        }
    }
}

.product-add__button {
    &.button--apple-pay {
        &, &:hover, &:active {
            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }
}

@include media-query(small-up) {
    .product-attribute__list {
        .product-attribute--last:not(.product-attribute--color) {
            // max-width: 11.5rem;
        }
    }

    .social-item__kakaotalk {
        display: none;
    }
}

.modal__body {
    .product-common__social-item {
        &.email{
            @include media-query(small-up) {
                margin-right: 0;
            }
        }

        &.kakaotalk{
            @include media-query(small-up) {
                display: none;
            }
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

@include media-query(small) {
    .product-attribute__size {
        &.form-control--select {
            font-size: 0.75rem;
        }
    }
}

.product-strap-choose-color {

    .pdp-straps__item {
        display: inline-block;
    }

    [data-strap-instock="false"] {
        opacity: 0.5;
        &[data-default-strap=true] {
            opacity: 1;
        }
    }
}

.personalisation {

    &__cta {
        pointer-events: auto;
        cursor: pointer;
        align-items: center;
        border: 1px solid #e7e7e7;
        display: flex;
        min-height: 85px;
        padding: 0;
        width: 100%;

        &:hover {
            .personalisation__icon--strap {
                color: $color-red;
                filter: invert(16%) sepia(100%) saturate(3677%) hue-rotate(344deg) brightness(73%) contrast(115%);
            }
        }

        .removeButton {
            font-size: 1rem;
            font-weight: 400;
            font-family: $font-family-serif;
        }
    }

    &__container {
        pointer-events: auto;
        cursor: pointer;
        align-items: center;
        border: 1px solid #e7e7e7;
        display: flex;
        min-height: 85px;
        padding: 0;
        &:hover {
            .personalisation__icon--engraving,
            .personalisation__icon--baraceletAdjustment,
            .personalisation__icon--embossing {

                color: #c10016;
                filter: invert(16%) sepia(100%) saturate(3677%) hue-rotate(344deg) brightness(73%) contrast(115%);
            }
        }
    }

    &__icon {
        width: 50px;
        display: inline-block;

        &:before {
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            content: " ";
            display: block;
            height: 36px;
            width: 17px;
            margin: 0 auto;
        }
    }

    &__icon--strap {

        &:before {
            background-image: url('../images/strap-icon.svg');
        }

    }

    &__content-wrapper {
        display: flex;
        flex: 1 2 auto;
        flex-direction: column;
    }

    &__icon--engraving,
    &__icon--embossing {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 16px;
        width: 17px;

        &::before {
            background-image: url('../images/engraving.svg');
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            content: " ";
            display: block;
            height: 36px;
            width: 17px;
        }
    }

    &__icon--embossing {
        &::before {
            background-image: url('../images/emboss.svg');
        }
    }

    &__icon--baraceletAdjustment {
        align-items: center;
        display: flex;
        justify-content: center;
        &::before {
            background-image: url('../images/ruler.svg');
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            content: " ";
            display: block;
            height: 36px;
            width: 17px;
        }
    }

    &__title {
        display: flex;
        margin-bottom: 4px;
        font-family: $font-family-sans;
        font-size: .875rem;
        font-weight: 600;
        line-height: 1.125rem;
        text-align: left;
        text-transform: uppercase;

        &--skeleton {
            display: none;
        }
    }

    &__section {
        display: flex;
        .value {
            max-width: 60%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: $font-family-serif;
        }

        &--skeleton {
            display: none;
        }
    }

    &__arrow {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: 40px;
        min-width: 40px;
        padding: 0 24px;
        @include bidirectionalProperty('transform', rotate(0deg), rotate(180deg));
        
        &::before {
            background-image: url('../images/right-arrow.svg');
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            content: " ";
            display: block;
            height: 14px;
            width: 8px;
        }

        .isHidden {
            clip: rect(0,0,0,0);
            border: 0;
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }
    }
}

@include media-query(small-up) {
    .product-attribute__list {
        .product-attribute--last:not(.product-attribute--color) {
            // max-width: 11.5rem;
        }
    }

    .social-item__kakaotalk {
        display: none;
    }
}

.modal__body {
    .product-common__social-item {
        &.email{
            @include media-query(small-up) {
                margin-right: 0;
            }
        }

        &.kakaotalk{
            @include media-query(small-up) {
                display: none;
            }
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

@include media-query(small) {
    .product-attribute__size {
        &.form-control--select {
            font-size: 0.75rem;
        }
    }
}

.item-info__container {
    position: relative;
    width: 100%;
}

.braceletAdjust-template--load {
    margin-top: 2rem;
}

.item-info__personalization-container { 
    background-color: $color-white;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    height: 0;
    display: none;
    
    .item-info__personalization-heading {
        margin-bottom: 13px;
    }
    
    .item-info__personalization-heading-secondary {
        margin: 25px 0;
    }
    .item-info__personalization-action-btn {
        &:not(:first-child) {
            margin-top: 1rem;
        }
    }
    .item-info__personalization-seperator {
        display: block;
        border-top: 1px solid $color-grey-3;
        margin: 30px 0;
    }
    .product-strap-add {
        margin-top: 1.5rem;
        .product-strap-add__product-tilelist {
            padding: 0;
            .product-strap-add__product-tile {
                @media screen and (min-width:64rem) {
                    margin: 0 20px 20px 0;
                }
                .pdp-straps__item-image-wrapper {
                    width: 100px;
                    .pdp-straps__item-image {
                        left: -86%;
                    }
                }
            }
        }
    }
}

.personalisation__cta-close {
    display: none;
    margin-top: 40px;
    left: 50%;
    transform: translateX(-50%);
    
    &::before {
        background-image: url('../images/right-arrow.svg');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        content: " ";
        display: inline-block;
        height: 14px;
        width: 8px;
        transform: rotate(180deg);
    }
}

.personalization-mode-on {

    .header  {
        .fixit--active {
            position: relative;
        }
    
    }

    .fixit-placeholder {
        display: none;
    }

    .pdp-main__breadcrumbs,
    .product-badge--novelty,
    .product-attribute__size-chart-trigger {
        display: none;
    }

    .item-info__container {
        display: none;
    }
    .item-info__container,
    .personalisation__cta {
        pointer-events: none;
        opacity: 0;
        height: 0;
        min-height: 0;
    }

    .item-info__personalization-container {
        pointer-events: auto;
        opacity: 1;
        height: auto;
        display: block;
        z-index: 2;
        width: 100%;
        
        @include media-query(medium-up) {
            width: 80%;
        }
    }

    .item-info__personalization-action-cta-wrapper {
        position: sticky;
        bottom: 0;
        background: $color-white;
        z-index: 1;
    }
    
    .pdp-main__section {
        max-width: 40rem;
    }
    
    .pdp-main__details {
        padding: 0;
        top: 0 !important;
        position: relative;
    }
    
    .product-gallery__wrapper {
        li {
            opacity: 0;
            height: 0;
        }
        li:first-child {
            opacity: 1;
            height: auto;
        }
    }
    
    .personalisation__cta-close {
        display: inline-block;
        margin: 25px 0;
    }
    
    .pdp-main {
        overflow: visible;

        &__media {
            animation-name: animation2;
            animation-duration: 1s;
            padding-left: 0;
            width: 90%;
            @include media-query(small) {
                width: 100%;
            }
        }
        
        @keyframes animation2 {
            0% {
                width: 500px;
                height: 500px;
                left: 0;
                top: 0;
            }
        
            100% {
                width: 100%;
                height: 100%;
            }
        }
        
        &__section {
            &--attributes {
                animation-name: animation1;
                animation-duration: 1s;
            }
        }
        
        @keyframes animation1 {
            0% {
              opacity: 0;  
            }
        
            100% {
             opacity: 1;
            }
        }
    }

}

.product-strap-add,
.product-strap-choose-color {

    .pdp-straps__item-image-wrapper {
        background-color: $color-grey-3;
        padding-bottom: 0;
        border-bottom-width: 3px;
        text-align: center;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        transition: .4s;
        max-width: 5.75rem;
    }
    
    .pdp-straps__item-image {
        border: none;
        max-width: initial;
        position: absolute;
    }

}

.product-strap-add {

    &__product-tile {
        display: inline-block;
        margin: 0 10px;
        overflow: hidden;
        
        label {
            padding: 0 !important;
            cursor: pointer;
            
            &:after {
                display: none;
            }
            
            &:before {
                visibility: hidden;
            }
        }
        
        .pdp-straps__item-image-wrapper {
            width: 100%;
            height: 100px;
        }
        
        .pdp-straps__item-image {
            transform: rotate(-90deg) translate(-29%, calc(100% - 140px));
            bottom: 0;
            @include media-query(small-up) {
                transform: rotate(-90deg) translate(-29%, calc(100% - 130px));
            }
        }
        
        .checkmark {
           position: absolute;
           top: 5px;
           right: 5px;
           height: 20px;
           width: 20px;
           border: 1px solid $color-grey-3;
           background-color: $color-white;
           z-index: 1;
           
           &:after {
                content: "";
                position: absolute;
                display: none;
                left: 6px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid $color-black;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }
        
        input:checked ~ .checkmark {
          background-color: $color-white;
        }
        
        input:checked ~ .checkmark:after {
          display: block;
        }

        .form-check-input {
            display: none;
        }

    }
    
    &__product-tilelist {
        padding: 0 30px;
        margin-bottom: 40px;
        
        .slick-prev,
        .slick-next {
            top: 40%;
        }
        
        .slick-prev {
            left: -15px
        }
        .slick-next {
            right: -15px;
        }
    }

    [data-strap-instock="false"] {
        opacity: 0.5;
    }

    .fluid-type--deka-hecto {
        font-size: 18px;
        font-weight: 600;
    }

    .component-copy__title--small {
        font-size: 14px;
        font-weight: 600;
    }

    .personalisation__cta-close {
        font-size: 12px;
        font-weight: 400;
        &::before {
            position: relative;
            top: 2px;
            right: 8px;
        }
    }

    &__strap-name {
        font-size: 16px;
        font-weight: 400;
        font-family: $font-family-serif;
    }

    &__strap-price {
        font-size: 14px;
        font-weight: 600;
        font-family: $font-family-sans;
        margin-top: 10px;
    }
}

.product-strap-choose-color {

    .pdp-straps__item-image {
        transform: rotate(-90deg);
        left: -130%;
        top: 131px;
        bottom: 0;
    }

    .pdp-straps__item-label {
        text-align: center;
        display: block;
    }

    .pdp-straps__item {
        cursor: pointer;

        @include media-query(medium-up) {
            margin: 0 20px 20px 0;
        }
    }
    
    .pdp-straps__item-image {
        border: none;
        max-width: initial;
        transform: rotate(-90deg);
        position: absolute;
        left: -112%;
        top: 131px;
        bottom: 0;
    }
    
    .slick-list {
        margin: 0 2rem;
    }

    .slick-arrow {
        top: 22%;
    }

    .slick-prev {
        left: -5px;
    }
    
    .slick-next {
        right: -5px;
    }

    .fluid-type--deka-hecto {
        font-size: 18px;
        font-weight: 600;
    }

    .component-copy__title--small {
        font-size: 14px;
        font-weight: 600;
    }

    .personalisation__cta-close {
        font-size: 12px;
        font-weight: 400;
        &::before {
            position: relative;
            top: 2px;
            right: 8px;
        }
    }
}
.engraving-pdp-modal{
    display: none;
}

.product-addrefill__button {
    margin-bottom: 20px;
}
.pdp__recommendations {
	.product-tile__quickshop {
		bottom: 0;
	}
	h1.module-container__title {
		margin: 30px 0;
	}
	.product-tile {
		&:after {
			bottom: 0;
		}
	}
}
.pdp-related-creations {
    .product-tile__media {
        background: none;
        &.bg--grey-image {
            background-image: none;
        }
        .product-tile__packshots {
            height: auto;
        }
        .product-slot-image__scrollbar {
            width: 89%;
            .swiper-scrollbar-drag {
                left: 6%;
            }
        }
    }
	.product-tile {
		.product-tile__discover-btn {
            position: relative;
			bottom: 15px;
            @include media-query(small-up) {
                bottom: 0;
            }
		}
		&:after {
			bottom: 0;
		}
	}
}
.pdp,
.page--homepage,
.experience-region {
    .product-tile__quickshop {
        bottom: 0;
        margin-top: 1rem;
        padding-bottom: 1rem;

        @include media-query(small-up) {
            margin-bottom: 1rem;
            padding-bottom: 0;
        }
    }
    .product-tile__discover-btn {
        margin: 1rem 0;
    }
}

.pdp-related-creations,
.pdp__recommendations {
	.product-tile {
		.product-tile__discover-btn {
            position: relative;
            bottom: 0;
            margin: 20px 0 0;

            @include media-query(small-up) {
                bottom: 15px;
                position: relative;
                left: 0;
            }
		}
        .product-attribute--size {
            + {
                .cart-and-ipay {
                    >.stock-notify-subscription {
                        margin-top: 0;
                    }
                }
            }
        }
		&.quick-shop-active {
            .product-tile__discover-btn {
                bottom: 0;
            }
		}
	}
}
.pdp-main__section {
	.product-attribute__size {
		margin-bottom: 1rem;
	}
}
.close-step.back-to-product {
    margin: 0 auto;
}

.engrav-label {
	label.form-check-button {
		text-transform: capitalize;
	}
}
.pdp.set--page-motion-ready.opacity {
	.fixit-placeholder {
		height: auto !important;
	}
}
.pdp {
	.product-attribute {
		.product-attribute__contents {
			.product-attribute__size {
				text-align: left;
			}
		}
	}
}
.page--homepage,
.experience-region {
    @include media-query(small-up) {
        .module-carousel .module-carousel__item {
            .product-tile.quick-shop-active {
                margin-bottom: 0;
                padding-bottom: 0;
                position: relative;
            }
        }
    }
}

.quickshop-dialog-overlay {
    .pdp,
    .cart,
    .page--homepage,
    .experience-region,
    .shop-look-overlay-content {
        .slick-slider {
            @include media-query(small) {
                &.productlist-quick-shop-active {
                    position: fixed;
                    max-width: 100%;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 9;
                    
                    .slick-arrow {
                        display: none;
                    }

                    .slicklist-quick-shop-active {
                        height: 100% !important;
    
                        > .slick-track {
                            width: 100% !important;
                            height: 100%;
                            transform: none !important;
                            
                            > .slick-slide {
                                opacity: 0;
        
                                &.slide-quick-shop-active {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-query(small) {
    .product-strap-choose-color,
    .product-strap-add {
        .fluid-type--deka-hecto {
            font-size: 16px;
            font-weight: 600;
        }
    }
}
