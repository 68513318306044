@import  'slick-carousel/slick/slick.scss';

//Add this class if you need to flex-ify Slick.
.slider--flex {
    display: flex;

    &.slick-initialized {
        flex-direction: column;
    }

    //This breaks carousels on IE.
    // .slick-list {
    //     flex: 1 0 100%;
    // }

    .slick-track {
        height: 100%;
        min-width: 100%;
    }
}

//Used to vertically align items within a flex slider.
.slider--v-align-center {
    &.slick-initialized {
        .slick-slide {
            flex-basis: auto;
            height: auto;
        }
    }

    .slick-track {
        display: flex;
    }
}

.slider--h-align-center {
    &:not(.slick-can-scroll) {
        .slick-track {
            display: flex;
            justify-content: center;
        }
    }
}

.slick-arrow {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 2.375rem;
    height: 2.375rem;
    font-size: $font-size-centi;
    background-color: $color-white;
    border-radius: 50%;

    cursor: pointer;

    transition: transform $transition-speed-fast $transition-ease-out;

    &.slick-disabled {
        // opacity: .5;
        // color: $color-grey-5
        @include set-invisible();
    }
}

    .slick-prev:not(.slick-disabled) {
        &:hover {
            transform: translateX(-10%);
        }
    }

    .slick-next:not(.slick-disabled) {
        &:hover {
            transform: translateX(10%);
        }
    }

.slider--arrows-bottom {
    .slick-arrow {
        position: absolute;
        top: 100%;
        transform: translate(0, -70%);
    }

    .slick-prev:not(.slick-disabled) {
        &:hover {
            transform: translate(-10%, -70%);
        }
    }

    .slick-next:not(.slick-disabled) {
        &:hover {
            transform: translate(10%, -70%);
        }
    }
}

.slider--arrows-center {
    .slick-arrow {
        @include vertical-align($transform: true, $posAdj: var(--arrows-adjustment-y, 0px));
    }

    .slick-prev:not(.slick-disabled) {
        &:hover {
            transform: translate(-10%, -50%);
        }
    }

    .slick-next:not(.slick-disabled) {
        &:hover {
            transform: translate(10%, -50%);
        }
    }
}

.slick-dots {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding-left: 0;

    .slick-active {
        button:after {
            background-color: currentColor;
        }
    }

    li {
        font-size: 0;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        width: 1.45rem;
        height: 1.45rem;
        border-radius: 50%;
        font-size: 0;

        &:after {
            display: block;
            width: .5rem;
            height: .5rem;
            border-radius: inherit;
            border: solid .0625rem;
            content: '';
        }
    }
}

.slider--dots-outer {
    .slick-dots {
        margin-top: 1rem;
    }
}

.slider--dots-inner {
    .slick-dots {
        position: absolute;
        left: 0;
        right: 0;
    }
}

.slider--dots-left {
    .slick-dots {
        justify-content: flex-start;
    }
}

.slider--dots-right {
    .slick-dots {
        justify-content: flex-end;
    }
}


[class*="slider--pre-layout"] {
    &:not(.slick-initialized) {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
    }
}


//Provide a "pre init" layou for your carousels.
//This is helpful to avoid the page from jumping around while the carousel is ready.
//breakpoint-specific layouts are available further down.
@for $i from 1 through 8 {
    .slider--pre-layout-#{$i} {
        &:not(.slick-initialized) {
            > *:not(.slick-item) {
            flex: $i 0 percentage(1/$i);
        }
        }
    }
}

@include media-query(medium-up) {
    [class*="slider--lg-pre-layout"] {
        &:not(.slick-initialized) {
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
        }
    }

    @for $i from 1 through 8 {
        .slider--lg-pre-layout-#{$i} {
            &:not(.slick-initialized) {
                > *:not(.slick-item) {
                flex: $i 0 percentage(1/$i);
            }
            }
        }
    }
}

@include media-query(small-up) {
    .slick-prev {
        left: $gutter-outer;
    }

    .slick-next {
        right: $gutter-outer;
    }

    .slider--arrows-outer {
        &.slick-has-arrows {
            padding-left: 6.25rem;
            padding-right: 6.25rem;
        }

        .slick-next {
            right: 2rem;
        }

        .slick-prev {
            left: 2rem;
        }
    }

    .slider--arrows-tile-center {
        .slick-arrow {
            top: 25%;
        }
    }

    .slider--dots-inner {
        .slick-dots {
            bottom: 1.25rem;
        }
    }

    [class*="slider--md-pre-layout"] {
        &:not(.slick-initialized) {
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
        }
    }

    @for $i from 1 through 8 {
        .slider--md-pre-layout-#{$i} {
            &:not(.slick-initialized) {
                > *:not(.slick-item) {
                flex: $i 0 percentage(1/$i);
            }
            }
        }
    }

    //Add this class if you want to Slick to support the row/col grid.
    .slider--row {
        &:not(.slick-initialized),
        .slick-list {
            margin-left: -$gutter-inner;
            margin-right: -$gutter-inner;
        }
    }
}

@include media-query(small) {
    .slick-prev {
        left: $gutter-outer-centi;
    }

    .slick-next {
        right: $gutter-outer-centi;
    }

    .slider--arrows-tile-center {
        .slick-arrow {
            margin-top: 50%;
            transform: translateY(-50%);
        }
    }

    .slider--dots-inner {
        .slick-dots {
            bottom: 1rem;
        }
    }

    [class*="slider--sm-pre-layout"] {
        &:not(.slick-initialized) {
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
        }
    }

    @for $i from 1 through 8 {
        .slider--sm-pre-layout-#{$i} {
            &:not(.slick-initialized) {
                > *:not(.slick-item) {
                flex: $i 0 percentage(1/$i);
            }
            }
        }
    }

    //Add this class if you want to Slick to support the row/col grid.
    .slider--row {
        &:not(.slick-initialized),
        .slick-list {
            margin-left: -$gutter-inner-deci;
            margin-right: -$gutter-inner-deci;
        }
    }
}

.module-2col, .module-4col {
    @include media-query(small) {
        &.row {
            display: block;
        }
    }
    &.module-carousel {
        @include media-query(small) {
            .slick-list {
                margin-bottom: 1rem;
            }
            &.half-slide {
                .slick-list {
                    padding: 0 10% 0 0;
                }
            }
        }
    }
}

.module-carousel {
    @include media-query(small) {
        &.half-slide {
            .slick-list {
                padding: 0 22.5% 0 0;
            }
            .slick-slide:not(.slick-active) {
                opacity: 0.4;
            }
            .slick-arrow {
                top: 97.5%;
            }
            .slick-dots {
                margin-top: 1.7rem;
            }
            .product-tile__name {
                font-size: 0.75rem;
            }
        }
    }
}

.module-2col.module-carousel {
    &.slick-initialized {
        margin-bottom: 3rem;
    }
}

.module-4col, .module-2col {
    &.slick-initialized {
        margin-bottom: 5rem;
        .slick-arrow {
            background-color: #7d7c7c;
            color: #fff;
            top: 104%;
        }
        .slick-prev {
            left: 1.25rem;
        }

        .slick-next {
            right: 1.25rem;
        }
        
        .slick-dots {
            display: flex !important;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin-top: 1rem;
            position: relative;
            top: 1rem;
        }
        
        .slick-dots li {
            margin: 0 7px;
        }
        
        .slick-dots li button {
            padding: 0;
            background: #7d7c7c;
            border: none;
            cursor: pointer;
            opacity: 0.3;
            border-radius: 0;
            color: transparent;
            width: 3.14rem;
            height: 0.2rem;
        }
        
        .slick-dots li button:before {
            display: none;
        }
        
        .slick-dots li.slick-active button {
            opacity: 1;
            background: #d50032;
        }
    }
}

.module-container--spacing-bottom-small {
    .module-4col, .module-2col {
        &.slick-initialized {
            margin-bottom: 3.5rem;
        }
    }
}

.module-container--spacing-bottom-regular {
    .module-4col, .module-2col {
        &.slick-initialized {
            margin-bottom: 1.25rem;
        }
    }
}

.module-container--spacing-bottom-large {
    .module-4col, .module-2col {
        &.slick-initialized {
            margin-bottom: 0;
        }
    }
}

.promo-banner-list {
    .promo-banner-list__wrap {
        text-align: center;
    }
    .slick-arrow {
        background-color: transparent;
        border-radius: 0;
        &.slick-prev {
            left: 0;
        }
        &.slick-next {
            right: 0;
        }
    }

    .promo-banner-list__item {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        float: none;
        text-align: center;
        @include media-query(small) {
            padding: 0 2rem;
            .promo-banner {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }
}