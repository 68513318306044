

.product-badge-list--floating {
    position: absolute;
    top: .625rem;
    left: .625rem;
    z-index: 2;
}

.product-badge-list--full-product {
    margin-bottom: 1rem;
}

.product-badge-list--line-item {
    margin-bottom: .5rem;
}

.product-badge {
    display: inline-block;
    
    &:not(:last-child) {
        margin-bottom: .5em;
    }
}

.product-badge--bordered {
    background-color: $color-white;
    border: solid .0625rem $color-grey-4;
    padding: .6em .75em;
}

.product-badge--novelty {
    font-size: .75rem;
    font-weight: 400;
    text-transform: uppercase;
    background-color: #fff;
    z-index: 2;
    position: absolute;
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70%;
}

.vto-badge {
    cursor: pointer;
}

.product-tile .product-badge--novelty { 
    display: block;
    left: 8px !important;
    margin-top: 10px;
}

.product-gallery__wrapper {
    position: relative;
    .product-badge--novelty {
        top: 16px;
        left: 16px;
        padding: 4px 8px;
        @include media-query('medium-up') {
            left: 2px;
            top: 10px;
        }
    }
}

.product-gallery--pdp {
    position: relative;
}

.product-tile {
    .product-badge--novelty {
        max-width: 67%;
    }
        
    &.quick-shop-active {
        .product-badge--novelty {
            max-width: 50%;
        }
    }
}
    
.feed-view {
    .product-tile {
        .product-badge--novelty {
            max-width: 70%;
        }
            
        &.quick-shop-active {
            .product-badge--novelty {
                max-width: 60%;
            }
        }
    }
}

@include media-query(small-up-to-medium) {
    .product-grid {
        .product-tile.quick-shop-active {
            .product-badge--novelty { 
                left: 21%!important;
                top: 30px;
                max-width: 40%;
            }
        }
    }
}

@include media-query(small) {
    .product-grid {
        .product-tile.quick-shop-active {
            .product-badge--novelty { 
                left: 21%!important;
                top: 30px;
                max-width: 40%;
            }
        }
    }
}
