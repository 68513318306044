
.icon-tile {
    position: relative;
    padding: 1.25rem;
}

    .icon-tile__media {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        @include media-query(small) {
            height: 3rem;
        }
    }

    .icon-tile__content {
        margin-top: .75rem;
    }

    .icon-tile__actions {
        margin-right: -.25rem;
        margin-left: -.25rem;
    }

        .icon-tile__cta {
            margin-right: .25rem;
            margin-left: .25rem;

            &:not(:last-child) {
                margin-bottom: .75rem
            }
        }

@include media-query(small-up) {
    .icon-tile__description {
        margin-top: .5em;
    }

    .icon-tile__actions:not(:first-child) {
        margin-top: 1rem;
    }
}

@include media-query(small) {
    .icon-tile__description {
        margin-top: .35rem;
    }

    .icon-tile__actions:not(:first-child) {
        margin-top: .825rem;
    }
}
