.stl-image-container {
    position: relative;
    display: inline-block;
}

.minicart-icon-container {
    cursor: pointer;
    display: inline-block;
    width: 34px;
    height: 34px;
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 100%;
    &::before {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        background: #fff;
        border-radius: 100%;
        top: 6px;
        left: 6px;
        z-index: 4;
    }
    svg.shop-the-look-icon {
        height: 8px;
        position: relative;
        width: 8px;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 7px;
        z-index: 4;
        fill: #000;
    }
    .shop-look-overlay-text {
        position: absolute;
        top: 50%;
        left: 0;
        height: 34px;
        line-height: 1;
        width: 22px;
        background-color: rgba(0, 0, 0, 0.3);
        color: #fff;
        padding: .5rem 1rem .5rem 3rem;
        font-size: 0.75rem;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        text-transform: uppercase;
        transition: width 0.4s ease, opacity 0.4s ease, background-color 0.4s ease;
        opacity: 0;
        z-index: 3;
        transform: translateY(-50%);
    }
    &.active, &:hover {
        background-color: transparent;
        .shop-look-overlay-text {
            width: auto;
            opacity: 1;
        }
    }
}

.video-control-enabled  {
    .vjs-user-active + .minicart-icon-container {
        bottom: 72px;
    }
}

/* Initially hide the text */
.stl-button-text {
    white-space: nowrap;
    overflow: hidden;
    transform: translateX(-100%);
    transition: opacity 0.3s ease 0.4s, transform 0.3s ease 0.4s;
}

.shop-look-overlay {
    position: fixed;
    top: 0;
    right: -300%;
    width: 600px;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    transition: right 0.8s ease-in-out;
    z-index: 1001;
    overflow-y: auto;
}

.shop-look-overlay.open {
    right: 0;
}

.shop-look-overlay .shop-overlay-close-button {
    position: absolute;
    top: 28px;
    right: 38px;
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: bold;
}

.shop-look-overlay-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
    z-index: 1000;
    display: none;
}

.shop-look-overlay-background.open {
    display: block;
    opacity: 1;
}

.shop-look-overlay-heading {
    margin: 1.75rem 0 1.75rem 2rem;
    font-size: 1.2rem;
    text-transform: uppercase;
}

.shop-look-overlay-divider {
    border: 0;
    height: 1px;
    background: #ccc;
    margin: 0 20px;
}

.shop-look-overlay-product-grid {
    padding: 1.5rem;
    margin: 0 1rem;
    @include media-query(small-up) {
        margin: 0.5rem 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .product {
        flex-basis: 100%;
        max-width: 100%;
        padding-left: .46875rem;
        padding-right: .46875rem;
        @include media-query(small-up) {
            flex-basis: 50%;
            max-width: 50%;
        }
    }
}

@media (max-width: 767px) {
    .shop-look-overlay {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 480px) {

    .shop-look-overlay-heading {
        font-size: 0.9rem;
    }

}

.shop-look-overlay-product-grid {

    .product-tile--default .product-tile__body {
		margin-bottom: 0;
        min-height: 9rem;
	}

    .product-tile__quickshop {
        margin-top: auto;
    }

    .product-tile__quickshop-trigger {
        margin-top: 0;
    }

    .slick-prev, .slick-next {
        padding: 10px;
        top: 32%;
        zoom: 0.9;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
    }
    
    .slick-prev {
        left: -8px;
    }
    
    .slick-next {
        right: -8px;
    }
    
    .slick-dots {
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 0;
    }
    
    .slick-dots li {
        margin: 0 7px 16px;
    }
    
    .slick-dots li button {
        padding: 0;
        background: #7d7c7c;
        border: none;
        cursor: pointer;
        opacity: 0.3;
        border-radius: 0;
        color: transparent;
        width: 1.5rem;
        height: 0.2rem;
    }
    
    .slick-dots li button:before {
        display: none;
    }
    
    .slick-dots li.slick-active button {
        opacity: 1;
        background: #d50032;
    }
}

.loader {
    .loader-indicator, .face-loader {
        top: 50%;
        transform: translateY(-50%);
    }
}