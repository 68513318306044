html {
    //Certain modals contain forms,
    //which are known to cause scrolling issues on iOS devices
    &.cancel-scroll--force {
        overflow: hidden;
        height: 100%;
    }
}

body {
    .cancel-scroll &{
        overflow: hidden;
    }

    .cancel-scroll--force & {
        overflow: hidden;
        height: 100%;
    }
}

.window-modal {
    z-index: 10000; //Nothing should be higher than this.
    overflow: auto;
    display: flex;
    // justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($color-black, 0.75);
    -webkit-overflow-scrolling: touch;
    transition-property: opacity, visibility;
    -ms-flex-align: stretch;

    &.modal-active {
        transition-timing-function: $transition-ease-out;
        transition-duration: $transition-speed-fast;
    }

    &:not(.modal-active) {
        pointer-events: none;
        transition-timing-function: $transition-ease-in;
        transition-duration: $transition-speed-fast;
        @include set-invisible();
    }

    &.modal-in-background {
        z-index: 9999;
        background-color: transparentize($color-black, 0.8);
        pointer-events: none;
        opacity: 0;
    }
}

    .window-modal__content {
        // z-index: 1;
        position: relative;
        width: 100%;
        min-height: 6.25rem;
        background-color: $color-panel-background;
        transition-property: top, bottom, right, left, height, transform, opacity, visibility;
        transition-timing-function: $transition-ease-out;
        transition-duration: $transition-speed-fast;
        -ms-flex-item-align: baseline;

        // box-shadow: 0 .25rem 8rem transparentize($color-black, .85);

        .modal-active & {
            transition-duration: $transition-speed-fast;
            transition-timing-function: $transition-ease-out;
        }

        .modal-in-background & > * {
            opacity: .5;
        }

        > .modal-container--static {
            display: block;
        }
    }

    .window-modal__close {
        z-index: 10;
        position: absolute;
        height: 2.5rem;
        width: 2.25rem;

        &:hover:after {
            content: url(get-icon(close, $color-secondary));
        }

        &:after {
            content: url(get-icon(close, $color-body-copy));
            width: 0.875rem;
            height: 0.875rem;
            display: inline-block;
        }
    }

/*------------------------------------*\
  #UTILITY-MODAL-CLASSES
\*------------------------------------*/
.modal-container--static {
    display: none;
}

.modal--full {
    .window-modal__content {
        max-width: none;
    }
}

.modal--flex {
    .window-modal__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

.modal--hideable {
    &.modal-in-background {
        @include set-invisible();
    }
}

/*------------------------------------*\
  #GENERIC-MODAL
  Use these if you don't know what else to use.
\*------------------------------------*/

.modal__header {
    text-align: center;
    border-top: solid .375rem $color-secondary;
    border-bottom: solid .0625rem $color-grey-3;
}

.modal__title {
    
}

.modal__description {
    line-height: $font-line-height-deka;
}

.modal__content {
    margin-top: 2rem;
}

.modal__actions {
    margin-top: auto;
}

    .modal__action {
        margin-top: 2.5rem;
    }

.modal__scrollable {
    max-height: 17.5rem;
    padding-bottom: 1.25rem;
}

/*------------------------------------*\
  #SPECIAL-CASES
\*------------------------------------*/

.modal--quickview {
    .window-modal__content {
        max-width: 60rem;
    }
}

.modal--store-locator {
    .window-modal__content {
        max-width: 68.125rem;
        padding-bottom: 4.75rem;
    }
}

// Override for Denali
[data-site='denali'] {
    .modal--store-locator {
        .window-modal__content {
            max-width: 75rem;
        }
    }
}

.modal--country-selector {
    .window-modal__content {
        max-width: 90rem;
    }
}

.modal--country-selector__content {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
}


.window-modal__content {
    max-width: 62.375rem;
    @include media-query(medium-up) {
        max-height: 100%;
        overflow-y: auto;
    }
}

.modal--small-width,
.modal--newsletter,
.modal--gift-personalisation,
.modal--geoip-suggestions {
    .window-modal__content {
        max-width: 45rem;
    }
}

.modal--geoip-suggestions {
    .window-modal__content {
        padding: 2rem;
    }

    &.toggle--active {
        .window-modal__content {
            max-width: 90rem;
        }
    }
}

.modal--personalisation {
    .window-modal__content {
        max-width: 58.25rem;
    }
}

.modal--address-suggestion {
    .window-modal__content {
        max-width: 41rem;
    }

    .address-suggestion__item {
        border-top: 1px solid $color-black;
        font-size: $font-size-centi;
        padding: .625rem .313rem;
        cursor: pointer;

        &:last-child {
            border-bottom: 1px solid $color-black;
            margin-bottom: 2.85rem;
        }
    }
}

.modal--refill {
    .window-modal__content {
        padding-bottom: 0;
        > .window-modal__close {
            display: none;
        }
    }

    .window-modal__close {
        top: 1rem;
    }

    .modal__header,
    .modal__actions {
        position: sticky;
        z-index: 99;
    }

    .modal__header {
        top: 0;
        margin-bottom: 1rem;
        padding: 1.25rem 3.5rem;
    }

    .modal__actions {
        bottom: 0;
        padding: 1.25rem 0;
        border-top: solid .0625rem $color-grey-3;
    }
}

@include media-query(small-up) {
    body {
        .cancel-scroll--sm-up & {
            overflow: hidden;
        }
    }

    .window-modal__content {
        width: 100%;
        margin: auto;
        transform: scale(.95);

        @include set-invisible();

        .modal-active & {
            transform: scale(1);
            @include set-visible();
        }

        .modal-in-background & {
            // transform: scale(.95);
        }
    }

    .window-modal__close {
        top: 1.6rem;
        right: 1.125rem;
    }

    /*------------------------------------*\
      #GENERIC-MODAL
      Use these if you don't know what else to use.
    \*------------------------------------*/

    .modal--quickview {
        .window-modal__close {
            top: 1.25rem;
            right: 1.25rem;
        }
    }

    .modal--generic {
        .window-modal__content {
            max-width: 45rem;
            min-height: 21.25rem;
            padding-bottom: 2rem;
        }
    }

    .modal__header {
        margin-bottom: 2.25rem;
        padding: 1.45rem 3rem 1.65rem;
    }

    .modal--prompt {
        .window-modal__content {
            max-width: 33.625rem;
            min-height: 14.85rem;
            padding-bottom: 3rem;
        }
    }

    .modal--auth {
        padding: 1.25rem;
    }

        .modal--auth__content {
            margin-right: 2.5rem;
            margin-left: 2.5rem;
            margin-bottom: 2.5rem;
        }

    .modal--newsletter {
        .window-modal__content {
            padding-bottom: 5rem;
        }
    }

    .modal--gift-personalisation {
        .modal__content {
            padding-right: 5.625rem;
            padding-left: 5.625rem;
            padding-bottom: 4.375rem;
        }
    }

    .modal--personalisation {
        .modal__content {
            margin-top: 3.5rem;
            padding-bottom: 3.125rem;
        }
    }

    .modal--store-locator {
        padding: 2rem;
    }

    .modal--refill {
        .window-modal__content {
            max-width: 41rem;
            max-height: 35rem;
            overflow-y: auto;
        }
    }
}

@include media-query(small) {
    html {
        &.cancel-scroll {
            overflow: hidden;
            height: 100%;
        }
    }

    .window-modal__content {
        top: 0;
        min-height: 100%;
        margin-top: auto;
        padding-bottom: 3.75rem;
        transform: scale(.9);

        .modal-active & {
            transform: scale(1);
        }

        .modal-in-background & {
            // transform: translateY(55%);
        }
    }

    .window-modal__close {
        top: 1.5rem;
        right: .25rem;
    }

    /*------------------------------------*\
      #GENERIC-MODAL
      Use these if you don't know what else to use.
    \*------------------------------------*/

    .modal--quickview {
        .window-modal__close {
            top: .5rem;
            right: .5rem;
        }
    }

    .modal--generic {
        .window-modal__content {
            padding-bottom: 3.75rem;
        }
    }

    .modal--refill {
        .window-modal__close {
            top: 1.5rem;
            right: .75rem;
        }
    }

    .modal__header {
        margin-bottom: 2rem;
        padding: 1.65rem 2.5rem 1.5rem;
    }

    .modal--prompt {
        .window-modal__content {
            padding-bottom: 3.75rem;
        }
    }

    /*------------------------------------*\
      #UTILITY-MODAL-CLASSES
    \*------------------------------------*/

    .modal__main--set-center {
        margin: auto;
    }
}

html[lang="th-TH"] {
    .modal__content {
        .body-type--deci {
            font-size: 0.8rem;
        }
    }
    .login__tab-list {
        .login__tab-item {
            font-size: 1rem;
        }
    }
    .registration {
        .form-check-label,
        .form-check-description,
        .cms-generic-copy {
            font-size: 0.8rem;
        }
        .iti__dropdown-label {
            margin-left: 0;
            top: -1.5rem;
        }
    }
}

.modal--product-strap-status {
    .window-modal__content {
        text-align: center;
        border-top: 0.375rem solid $color-secondary;
        padding: 1.45rem 3rem 1.65rem;
        min-height: 6.25rem;

        @include media-query(medium) {
            margin-top: initial;
        }
    }
}