

@include media-query(medium-up) {
    .footer-copyright__link {
        margin-left: 2.2em;
    }
}

@include media-query(medium) {
    .footer-copyright__link {
        margin-top: .85em;

        &:not(:last-child) {
            margin-right: 1.55em;
        }
    }
}
