
[data-page-motion] {
    &.set--page-motion-ready {
        transition-property: opacity;
        transition-duration: $transition-speed-mid-slow;
        transition-timing-function: $transition-ease-bezier;
    }

    &:not(.set--page-motion-ready) {
        opacity: 0;
    }

    [data-motion] {
        &.set--motion-trigger {
            transition-duration: $transition-speed-slow;
            transition-timing-function: $transition-ease-bezier;
        }

        //All motion elements start from opacity
        &:not(.set--motion-trigger) {
            opacity: 0;
        }
    }

    .set--motion-opacity,
    .set--motion-opacity-delay {
        &.set--motion-trigger {
            transition-property: opacity;
        }
    }

    .set--motion-opacity-delay {
        &.set--motion-trigger {
            transition-delay: $transition-speed-slow;
        }
    }

    .set--motion-scale-large {
        &.set--motion-trigger {
            transition-property: opacity, transform;
            transition-duration: $transition-speed-normal;
            transition-delay: $transition-speed-faster;
        }

        &:not(.set--motion-trigger) {
            transform: scale(3);
        }
    }

    [class*="set--motion-from-"] {
        &.set--motion-trigger {
            transition-property: opacity, transform;
        }
    }

    .set--motion-from-top {
        &:not(.set--motion-trigger) {
            transform: translateY(-2rem);
        }
    }

    .set--motion-from-bottom {
        &:not(.set--motion-trigger) {
            transform: translateY(2rem);
        }
    }

    .set--motion-from-left {
        &:not(.set--motion-trigger) {
            transform: translateX(-2rem);
        }
    }

    .set--motion-from-right {
        &:not(.set--motion-trigger) {
            transform: translateX(2rem);
        }
    }
}
