/*------------------------------------*\
  #ADDITIONAL RESETS
\*------------------------------------*/
html {
    font-size: 1rem; //This should always be 1rem to avoid weird calculations.
    font-family: $font-family-sans;
    line-height: $font-line-height-deka;

    -ms-overflow-style: -ms-autohiding-scrollbar;
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
}

html {
    scroll-behavior: smooth;
}

body {
    min-width: 320px; //Intentional px value to avoid custom font-sizes to override the actual min-width of the site.
    overflow-anchor: none;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    color: $color-body-copy;
    background-color: $color-body-background;

    @include bidirectionalProperty(letter-spacing, $font-letter-spacing-base, 0);
}

h1 {
    margin: 0;
}

iframe {
    border: none;
}

img,
video {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

legend {
    display: block;
    width: 100%; //Legends don't grow, they're born (with width issues).
}

address {
    font-style: normal;
}

ul, ol {
    padding-left: 1.25em;
}

hr {
    border: none;
    border-bottom: solid .0625rem;
}

//Sets a standard focus styles for elements across the site.
*:focus {
    outline-color: $color-accent-tertiary;
    outline-offset: .25rem;
    outline-style: solid;
    outline-width: .0625rem;

    .set--hide-click-focus & {
        outline: none;
    }

    &.set--inner-focus {
        outline-offset: -.125rem;
    }
}

*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.list--reset {
    padding-left: 0;

    li {
        list-style-type: none;
    }
}

.set--w-100 {
    width: 100%;
}

.set--w-75 {
    width: 75%;
}

.set--w-50 {
    width: 50%;
}

.set--w-30 {
    width: 30%;
}

.set--w-25 {
    width: 25%;
}

.set--h-100 {
    height: 100%;
}

.set--h-75 {
    height: 75%;
}

.set--h-50 {
    height: 50%;
}

.set--h-25 {
    height: 25%;
}

.scrollable {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    transform: translate3d(0,0,0);
}

/*------------------------------------*\
  Lazy Loading Transitions
\*------------------------------------*/

.blur-up {
    transition: opacity $transition-ease-out $transition-speed-faster;

    &.lazyload,
    &.lazyloading {
        opacity: .45;
        filter: blur(6px);
    }

    &.lazyloaded {
        filter: blur(0);
        opacity: 1;
    }
}

.opacity-up {
    transition: opacity $transition-ease-out $transition-speed-faster;

    &.lazyload,
    &.lazyloading {
        opacity: 0;
    }

    &.lazyloaded {
        opacity: 1;
    }
}

.partial-opacity-up {
    transition: opacity $transition-ease-out $transition-speed-faster;

    &.lazyload,
    &.lazyloading {
        opacity: .8;
    }

    &.lazyloaded {
        opacity: 1;
    }
}

//Special case to hide image while it's loading but show no transition when done.
//Safari has issues when transitioning to a default property, so we can't use 'opacity-up'.
.none-up {
    &.lazyload,
    &.lazyloading {
        opacity: 0;
    }
}

/*------------------------------------*\
  Object Fit helpers
\*------------------------------------*/

[class*="object-fit"] {
    width: 100%;
    height: 100%;
}

.object-fit--cover {
    object-fit: cover;

    //IE11< object-fit polyfill.
    font-family: 'object-fit: cover;';
}

.object-fit--contain {
    object-fit: contain;

    //IE11< object-fit polyfill.
    font-family: 'object-fit: contain;';
}


/*------------------------------------*\
  Third party styles
\*------------------------------------*/
.grecaptcha-badge {
    @include set-invisible();
}

@include media-query(small-up) {
    .scrollable--small-up {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@include media-query(small) {
    .set--small-w-100 {
        width: 100%;
    }
}

.item-added{
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
    position: absolute
}
