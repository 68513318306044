.card-number-wrapper {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: .25rem;
        right: 0;
        width: 3.75rem;
        height: 1.65rem;
        background-repeat: no-repeat;
        background-image: url('../images/payment-logos/generic.svg');
        background-size: contain;
    }

    &[data-type="visa"]::after {
        background-image: url('../images/payment-logos/visa.svg');
    }

    &[data-type="mastercard"]::after {
        background-image: url('../images/payment-logos/mastercard.svg');
    }

    &[data-type="amex"]::after {
        background-image: url('../images/payment-logos/amex.svg');
    }

    &[data-type="discover"]::after {
        background-image: url('../images/payment-logos/discover.svg');
    }

    &[data-type="maestro"]::after {
        background-image: url('../images/payment-logos/maestro.svg');
    }

    &[data-type="mada"]::after {
        background-image: url('../images/payment-logos/mada.svg');
    }

    &[data-type="jcb"]::after {
        background-image: url("../images/payment-logos/jcb.svg");
        background-size: contain;
    }
}
