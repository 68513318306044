
.header__utility-item--badged {
    position: relative;

    &.set--has-items {
        .header__utility-anchor {
            color: $color-secondary;
        }
    }

    &:not(.set--has-items) {
        .header__utility-badge--has-items {
            display: none;
        }
    }
}

    .header__utility-anchor {
        position: relative;
        color: $header-nav-color;
    }

        .header__utility-badge {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 1rem;
            height: 1rem;
            padding-right: .25em;
            padding-left: .25em;
            background-color: $color-white;
            border-radius: 1rem;
            font-size: .5625rem; //Intentionally not using a variable.
        }

@include media-query(small-up) {
    .header__utility-item {
        &:not(:last-child) {
            margin-right: 1.125rem;
        }
    }

    .header__utility-item--reverse-spacing {
        &:not(:first-child) {
            margin-left: 1.125rem;
        }
    }

    .header__utility-anchor--small-up-highlight {
        color: $color-secondary;
    }
}

@include media-query(small) {
    .header__utility-item {
        &:not(:last-child) {
            margin-right: 1.4rem;
        }
    }

    .header__utility-item--reverse-spacing {
        &:not(:first-child) {
            margin-left: 1.4rem;
        }
    }
}

.header-account__name {
    margin-right: 1.125rem;
    text-transform: uppercase;
    font-size: 0.75em;
}

.header__country-selector-anchor {
    @media screen and (max-width:1170px) {
        display: block;
    }
}