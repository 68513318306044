
.quickview {
    padding: .0625rem;
}

.quickview__main {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

    .quickview__attribute-list,
    .quickview__availability {
        margin-top: 1.5rem;
    }

        .quickview__promotions {
            margin-bottom: .75rem;
        }

        .quickview__price {
            margin-top: .875rem;
        }

            .quickview__price-value {
                margin-right: 1.125rem;
            }

        .quickview__footer {
            margin-top: 2rem;
        }

            .quickview__footer-section {
                &:not(:first-child) {
                    margin-top: 1.25rem;
                }
            }

.quickview__bundle-items {
    padding: 1.875rem;
}

@include media-query(small-up) {
    .quickview__main {
        justify-content: center;
    }

    .quickview__footer {
        position: relative;
    }

    .quickview-optionProduct {
        .slick-dots {
            overflow: hidden;
        }
    }
}

@include media-query(small) {
    .quickview__footer {

    }
}
