
.footer-content__heading {
    margin-bottom: 1.35em;
}

.footer-content__description {
    &:not(:last-child) {
        margin-bottom: .5rem;
    }
}

.footer-content__links {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
}

    .footer-content__link {
        margin-top: .5rem;
        margin-bottom: .5rem;
    }

.footer-content__img {
    margin-bottom: .875rem;
}

@include media-query(small-up) {
    .footer-content__link {
        &:not(:last-child) {
            margin-right: 1.25rem;
        }
    }
}

@include media-query(small-up-to-medium) {
    .footer-content-col {
        &:not(:last-child) {
            margin-bottom:  2.25rem;
        }
    }
}

@include media-query(small) {
    .footer-content-col {
        &:not(:last-child) {
            margin-bottom:  3.5rem;
        }
    }

    .footer-content__link {
        &:not(:last-child) {
            margin-right: 1.875rem;
        }
    }
}
