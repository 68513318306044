
/*------------------------------------*\
  #INPUT-RESETS
\*------------------------------------*/

[type="password"],
[type="text"],
[type="tel"],
[type="email"],
[type="search"],
[type="number"],
[type="date"],
select,
textarea {
    color: $color-body-copy;
    font-family: inherit;
    font-weight: $font-weight-light;
    appearance: none;
    border-radius: $input-border-radius;
    border: none;
    border-bottom: solid .0625rem $color-body-copy;
    outline: none;

    &::placeholder {
        color: $color-grey-6;
        font-family: $font-family-serif;
    }

    &:focus {
        &::placeholder {
            color: $color-grey-4;
        }
    }

    &.disabled, &[disabled] {
        color: $color-grey-4;
    }

    &:not([disabled]):not([readonly]):not(.is-invalid):focus {
        border-color: $color-body-copy;
    }
}

[type="password"],
[type="text"],
[type="tel"],
[type="email"],
[type="search"],
[type="number"],
[type="date"],
textarea {
    [dir="rtl"] & {
        direction: ltr;
        text-align: right;
    }
}

input[type="file"] {
    cursor: pointer;

    &.disabled, &[disabled] {
        cursor: not-allowed;
    }
}

//Moves Safari's autofill icon to the left so that it doesn't
//overlay the tooltip icon.
input::-webkit-contacts-auto-fill-button {
    position: relative;
    right: 1.5rem;
}

textarea {
    display: block;
    width: 100%;
    resize: none;
    border: solid .0625rem $color-body-copy;
    font-family: $font-family-serif;
}

select {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::-ms-expand {
        display: none;
    }
}

option {
    color: $color-body-copy;
}

/*------------------------------------*\
  #FORM-COPY
\*------------------------------------*/
.form-intro__title {
    margin-bottom: 1.125em;
}

.form-intro__copy {
    margin-bottom: 1.75em;
}

.form-control-disclaimer {
    margin-top: .75em;
}

.form-control-description {
    margin-top: .25em;
    font-size: $font-size-deci;
    color: $color-grey-5;
}

/*------------------------------------*\
  #TEXT AND SELECTS
\*------------------------------------*/
.form-control {
    width: 100%;
    height: 2.1875rem;
    background-color: $color-input-background;
    transition-property: color, border-color;
    transition-duration: $transition-speed-fast;
    transition-timing-function: $transition-ease-in;

    &.is-invalid {
        border-color: $color-error;
        // background-color: lighten($color-error, 52%);
        color: $color-error;
    }

    &.remove--border-styles {
        border-radius: 0;
        border-color: transparent;
    }

    &.remove--border-right-styles {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

    &.remove--border-left-styles {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }
}
.suggestions-options-select{
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
    position: absolute
}
//Add custom styles for text input fields.
.form-control--text {

}

.form-group {
    &.form-preferred-date {
        .form-control-label {
            position: relative;
            top: 8px;
        }
    }
}

.form-group__city {
    .form-control--select {
        &.is-invalid {
            & + .select2-container {
                .select2-selection--single {
                    border-color: $color-error;
                    color: $color-error;
                }
            }
        }
    }
}

.form-control--textarea {
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-left: .75rem;
    padding-right: .75rem;
    height: auto;
    min-height: 4.5rem;
}

.custom-select,
.form-control--select {
    padding-right: 1.5rem;
    background-size: .85rem .85rem; //Provide height + width separately to fix IE display.
    background-repeat: no-repeat;
    background-position: calc(100% - .1em) center;
    background-image: url(get-icon(angle-down, $color-body-copy));
    transition: color $transition-speed-faster $transition-ease-default;
    outline-offset: 0;

    &:-internal-autofill-selected {
        background-image: url(get-icon(angle-down, $color-body-copy)) !important;
    }
}

.form-control--small {
    height: 1.85rem;
}

.form-control--boxed {
    border: solid .0625rem $color-body-copy;
    padding-left: .625rem;
    padding-right: .625rem;

    background-position: calc(100% - .85em) center;

    &.form-control--select {
        padding-right: 2rem;
    }
}

.form-control--alt-font {
    font-family: $font-family-serif;
    color: $color-grey-5;
}

.form-control--combo {
    padding-left: .5rem;
    border-left: solid .0625rem;

    &.form-control--select {
        padding-right: 1.75rem;
        background-position: calc(100% - .65em) center;
    }
}

.form-control--combo-last {
    border-right: solid .0625rem;
}

.form-control--combo-no-border {
    border-left: none;
}

.form-control-label {
    display: inline-block;
    font-family: $font-family-serif;
    font-weight: $font-weight-light;
    // font-size: $font-size-deci;
    margin-bottom: .25rem;
    color: $color-grey-5;

    .required &:after {
        // margin-left: -.25em;

        content: "*";
    }
}

.form-control-label--inline {
    min-width: 9.375rem;
}

.form-control-label--spacing-deka {
    margin-bottom: .5em;
}

.form-control-label--spacing-hecto {
    margin-bottom: 1em;
}

.form-control__show-trigger {
    position: absolute;
    top: .625rem;
    right: .625rem;
}

    .form-control__label-show {
        display: block;

        .set--show-password & {
            display: none;
        }
    }

    .form-control__label-hide {
        display: none;

        .set--show-password & {
            display: block;
        }
    }

/*------------------------------------*\
  #CHECKBOXES AND RADIOS
\*------------------------------------*/
.form-check {
    position: relative;

    &__holder {
        position: relative;
    }
}

.form-check-group {
    margin-bottom: -.8125rem;
    margin-top: -.8125rem;
}

    .form-check-group__item {
        margin-bottom: .8125rem;
        margin-top: .8125rem;

        &:not(:last-child) {
            margin-right: 2rem;
        }
    }

    .form-check-list__item {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

.form-check-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

[class*="form-check-label"] {
    display: block;
    position: relative;
    min-height: 1px;
    padding-left: 2.2rem;
    line-height: 1.3; //Aligns label with checkbox. Intentionally not a variable.
    transition: color $transition-speed-fast $transition-ease-bezier;

    &:before, &:after {
        position: absolute;
    }

    &:before {
        top: .2rem;
        left: 0;
        width: .875rem;
        height: .875rem;
        border: solid .0625rem $color-body-copy;
        border-radius: $input-border-radius;
        content: '';
    }

    &:after {
        top: .3rem;
        left: .1rem;
        width: .65rem;
        height: .65rem;
    }
}

.form-check-label {
    &:after {
        opacity: 0;
        line-height: .62;
        transition: opacity $transition-speed-fast $transition-ease-bezier;
        content: url(get-icon(checkmark, $color-primary));
    }
}

.form-check-label--large {
    padding-left: 1.875rem;

    &:before {
        top: .05rem;
        width: 1.125rem;
        height: 1.125rem;
    }

    &:after {
        top: .25rem;
        left: .2rem;
        width: .75rem;
        height: .75rem;
    }
}

.form-check-label--radio {
    &:before, &:after {
        border-radius: 50%;
    }

    &:before {
        width: 1rem;
        height: 1rem;
    }

    &:after {
        top: .45rem;
        left: .25rem;
        width: .5rem;
        height: .5rem;

        background-color: $color-primary;
        transform: scale(0);
        transform-origin: center;
        transition: transform $transition-speed-fast $transition-ease-bezier;
        content: '';
    }
}

.form-check-description {
    margin-top: .75em;
    padding-left: 2.2rem;
}

.form-check-input:checked + .form-check-label,
.form-check-label.set--checked,
.form-check-label.toggle--active {
    color: inherit;

    &:after {
        opacity: 1;
        transform: scale(1);
        content: url(get-icon(checkmark, $color-primary));
    }
}

.form-check-input:checked + .form-check-label--radio,
.form-check-label--radio.set--checked,
.form-check-label--radio.toggle--active {
    &:after {
        opacity: 1;
        background-color: $color-primary;
        transform: scale(.8);
    }
}

.form-check-input:focus + [class*="form-check-label"],
[class*="form-check-label"]:focus {
    outline: none;

    &:before {
        outline-color: $color-accent-tertiary;
        outline-offset: .25rem;
        outline-style: solid;
        outline-width: .0625rem;
    }

    .set--hide-click-focus &:before {
        outline: none;
    }
}

.form-check-input:focus + label,
[class*="form-check-label"]:focus {
    outline-color: $color-accent-tertiary;
    outline-offset: .25rem;
    outline-style: solid;
    outline-width: .0625rem;

    .set--hide-click-focus & {
        outline: none;
    }
}

.form-check-input:disabled + [class*="form-check-label"],
[class*="form-check-label"].disabled {
    &:before {
        background-color: $color-grey-1;
        border-color: $color-grey-2;
    }

    &:after {
        color: $color-grey-2;
    }
}

.form-check-input.error + [class*="form-check-label"],
.form-check-input.is-invalid + [class*="form-check-label"] {
    &:before {
        border-color: $color-error;
    }
}

/*------------------------------------*\
  #FORM CHECK BUTTONS
\*------------------------------------*/

.form-check--button-item {
    &:not(:last-child) {
        margin-right: 1.25rem;
    }
}

.form-check-button {
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    height: 2.5rem;
    font-size: $font-size-centi;
    background-color: $color-grey-1;
    box-shadow: inset 0 0 0 0 $color-body-copy;
    cursor: pointer;

    transition-property: box-shadow, background-color;
    transition-duration: $transition-speed-faster;
    transition-timing-function: $transition-ease-out;
}

.form-check-foil-label {
    display:inline-block;
    padding: 0 1rem;
    height: 2.5rem;
    font-size: 0.90rem;
}

.form-check-dot-lable {
    display:inline-block;
    padding: 0 1rem;
    height: 2.5rem;
    font-size: 0.90rem;
}

.form-check-input:checked + .form-check-button {
    background-color: $color-white;
    box-shadow: inset 0 0 0 .125rem $color-body-copy;
}

.form-check-input:focus + .form-check-button {
    outline-color: $color-accent-tertiary;
    outline-offset: .25rem;
    outline-style: solid;
    outline-width: .0625rem;

    .set--hide-click-focus & {
        outline: none;
    }
}

.form-check-input:disabled + .form-check-button {
    opacity: .5;
}

.form-check-input.error + .form-check-button {
    border-color: $color-error;
}

/*------------------------------------*\
  #FORM GROUPS
\*------------------------------------*/
.form-group {
    margin-bottom: 2.85rem;
}

.form-group--short-spacing {
    margin-bottom: 1rem;
}

.form-group--no-spacing {
    margin-bottom: 0;
}

.form-group--password {
    position: relative;
}

//Use this to separate entire sections within a form or groups of forms.
.form-section {
    &:not(:first-child) {
        margin-top: 1.6rem;
    }
}

.form-combo {
    .form-control-label {
        margin-left: .5rem;
    }
}

.form-group {
    .form-label--policy {
        margin-left: 1.5rem;
    }
}


/*------------------------------------*\
  #FORM ACTIONS
\*------------------------------------*/

//Ensure this class is added on a block element, so that bottom/top  margins are collapsed.
.form-actions {
    margin-top: 2rem;
}

/*------------------------------------*\
  #FORM VALIDATION
\*------------------------------------*/
.success-feedback,
.invalid-feedback,
.form-invalid-feedback {
    display: block;
    margin-top: .25rem;
    font-size: $font-size-centi;

    &:not(.set--visible) {
        position: absolute;
        @include set-invisible();
    }
}

.invalid-feedback,
.form-invalid-feedback {
    color: $color-error;
}

.success-feedback {
    color: $color-success;
}

.form-invalid-feedback {
    margin-bottom: 1em;
}

.feedback--large-spacing-top {
    margin-top: .75em;
}

.feedback-banner {
    padding: .75rem 1rem;
    font-family: $font-family-serif;
    font-size: $font-size-deci;
    text-align: center;
}

.feedback-banner--error {
    background-color: $color-secondary;
    color: $color-white;
}

/*------------------------------------*\
  #FORM CONSTRAINTS
\*------------------------------------*/

.form-control__constraints {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2em;
    // color: $color-grey-5;
}

    .form-control__constraint {
        flex: 1 1 50%;
        margin-bottom: .75em;
        padding-right: 1.5rem;
    }

    .set--constraint-fail {
        color: $color-error;
    }

    .set--constraint-pass {
        color: $color-success;
    }

    .form-control__constraints-title {
        margin-bottom: .5em;
    }

/*------------------------------------*\
  #FLOATING-LABEL-FIELDS
\*------------------------------------*/

[data-floating-label] {
    position: relative;
    // padding-top: .625rem;

    .form-control-label {
        display: inline-flex;
        position: absolute;
        top: .5125rem;
        left: 0;
        transform-origin: top left;
        transition: opacity $transition-speed-faster $transition-ease-default,
                    transform $transition-speed-faster $transition-ease-default;
        cursor: text;
        pointer-events: none;
        &.text-line-clamp {
            display: block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &.set--floating-label {
        .form-control-label {
            transform: translateY(-1.5125rem);
        }
    }

    &:not(.set--floating-label) {
        .form-control--select {
            //IE doesn't like straight transparency here.
            color: transparentize($color-white, 0.99);
        }

        .form-control {
            &::placeholder {
                color: transparent;
            }

            //This seems to be required for IE.
            &:-ms-input-placeholder {
                color: transparent;
            }
        }
    }
}

.set--floating-label {
    .form-control-label--select {
        opacity: 1; //select dropdowns need this to avoid label overlay.
    }
}

@media (-ms-high-contrast: active) {
    .form-check-label {
        &:after {
            content: url(get-icon(checkmark, $color-white));
        }
    }

    .form-check-label--radio {
        &:after {
            border: solid .5rem;
        }
    }
}

@include media-query(small) {
    input,
    select,
    textarea {
        //Intentionally set to 1rem to force input fields have exactly 16px
        //font size, which prevents zooming in when focusing them on mobile.
        font-size: 1rem;
    }

    .form-control--select {
        font-size: $font-size-base;
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    /*------------------------------------*\
      #FORM ACTIONS
    \*------------------------------------*/
    .form-actions__item {
        &:not(:last-child) {
            margin-bottom: 1.25rem;
        }
    }
}

.iti__input[type="tel"] {
    @include bidirectionalProperty(direction, $rtlValue: ltr);
    @include bidirectionalProperty(text-align, $rtlValue: right);
}