@import "productStrapBar";

.product-tile {
    padding: 5px;
    margin: 20px 0;
    min-height: 21rem;

    @include media-query(small-up) {
        min-height: auto;
        padding: 5px;
        margin: 0;
    }

    // To handle slick-slider without left/right arrows
    .slick-list:only-child & {
        min-height: auto;
    }

    .price {
        .tiered {
            font-size: 0.875em;

            .value {
                font-weight: bold;
            }
        }
    }

    &.set--quickadd-active {
        pointer-events: none;
    }
}

.product-tile--default {
    position: relative;

    .product-tile__anchor {
        display: block;
    }

    .product-tile__body {
        padding-top: 1rem;
    }

    .product-tile__media {
        display: block;
        overflow: hidden;

        @include aspect-ratio(1, 1, true);
    }

    .product-tile__image {
        width: 60%;
        height: 60%;

        &.full-stretch-image {
            width: 100%;
            height: 100%;
        }

        &.image-zoom__lg:not(.full-stretch-image) {
            width: 80%;
            height: 80%;
        }
    }
}

.product-tile--overlay {
    padding: 1.25rem;
}

.product-tile--card {
    position: relative;
    margin-bottom: 1.25rem;

    .product-tile__body {
        flex: 1 1 70%;
        padding-right: .5rem;
        padding-left: .5rem;
    }

    .product-tile__media {
        display: block;
        overflow: hidden;
        flex: 1 1 30%;
    }
}

.product-tile__body {
    position: relative;
}

.product-tile__media {
    background-color: $color-grey-1;
}

.bg--grey-image {
    background-image: url('../images/fond-beige-horizontal.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
}

.product-tile__body-section {
    &:not(:first-child) {
        margin-top: .5rem;
    }
}

.product-tile__body-section {
    &.product-tile__material {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;

        .product-tile__anchor {
            text-decoration: none;
            @include media-query(small) {
                line-height: 23px;
            }
        }
    }
}

.product-tile__swatches {}

.product-tile__swatch {
    &:not(:last-child) {
        margin-right: .5rem;
    }
}

.product-tile__quickview {
    position: absolute;
    top: 0;
    right: 1rem;
    //This margin-top value must match the aspect ratio of the
    //product tile's media so that the CTA is aligned properly.
    margin-top: 100%;
    transform: translateY(calc(-100% - 1rem));
    opacity: 0;

    .product-tile:hover &,
    &:focus {
        opacity: 1;
    }
}

.product-tile__quickadd {
    position: relative;
    z-index: 1;

    &.set--added-to-cart {
        .product-tile__quickadd-trigger {
            // @include set-invisible();
        }

        .product-tile__quickadd-actions {
            display: block;
        }
    }
}

.product-tile__quickadd-panel {
    z-index: 2;
    position: absolute;
    bottom: -.5rem;
    left: -.5rem;
    width: calc(100% + 1rem);
    padding: 1rem;
    background-color: $color-panel-background;
    border: solid .0625rem;
    border-radius: .25rem;
    box-shadow: 0 .25rem .75rem transparentize($color-black, .75);
    transform: translateY(0);
    //Allow pointer events only in this container.
    pointer-events: all;

    transition-property: opacity, visibility, transform;

    &.set--quickadd-active {
        transition-duration: $transition-speed-normal;
        transition-timing-function: $transition-ease-bezier;
    }

    &:not(.set--quickadd-active) {
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-in;
    }

    &:not(.set--ready) {
        transform: translateY(.5rem);
        @include set-invisible();
    }
}

.product-tile__quickadd-actions {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: $color-white;
    border: solid .0625rem;
    border-radius: $input-border-radius;
}

.product-tile__wishlist {
    background-color: $color-white;
    display: flex;
    font-size: 15px;
    position: absolute;
    top: 14px;
    right: 15px;
    padding: .5em;
    border-radius: 50%;
    overflow: hidden;
    width: 30px;
    height: 30px;

    &.set--in-wishlist {
        .product-tile__wishlist-add {
            display: none;
        }

        .product-tile__wishlist-remove {
            display: block;
        }
    }
}

.product-tile__wishlist-remove {
    display: none;
}

.product-tile__refill-wrapper {
    min-height: calc(100vh - 12rem);

    .product-tile__media-container {
        margin: .5rem;
    }

    .product-tile__image {
        width: auto; //IE11 image stretch fix
    }
}

.product-tile__refill {
    margin-bottom: 1.5rem;
}

.product-tile__refill-check-label {
    position: absolute;
    right: 1rem;
    bottom: 1.5rem;
}

@include media-query(small-up) {
    .product-tile--card {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: $gutter-outer;
        padding-left: .5rem;
    }

    .product-tile {
        z-index: 0;
        transition-property: z-index;
        transition-duration: 0s;
        transition-delay: $transition-speed-fast;

        &:hover,
        &.quick-shop-active {
            z-index: 1;
            transition-delay: 0s;

            .product-tile__overlay {
                @include set-visible();
                background: transparent;
            }

            .product-tile__image--primary {
                transform: scale(1.1);
            }

            .product-tile__image--secondary {
                transform: scale(1.15);

                &.non-default-type {
                    transform: none;
                    @include set-visible();
                }
            }

        }
    }

    .product-tile__anchor {
        position: relative;
    }

    .product-tile__anchor,
    .product-tile__wishlist {
        z-index: 1;
    }

    .product-tile__wishlist {
        opacity: 0;

        transition-property: opacity;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-out;

        .product-tile:hover &,
        &:focus {
            opacity: 1;
            transition-timing-function: $transition-ease-in;
        }
    }

    .product-tile__overlay {
        z-index: 0;
        position: absolute;
        left: -10px;
        right: -10px;
        padding: 15px;
        top: 0;
        bottom: 0;
        width: auto;
        pointer-events: none;

        .product-tile__quickadd,
        .product-tile__overlay-actions {
            pointer-events: auto;
        }

        @include set-invisible();
    }

    .product-tile__anchor:focus,
    .product-tile--default:hover {
        .product-tile__image {
            transition-timing-function: $transition-ease-out;
        }

        .product-tile__image--secondary.lazyloaded {
            @include set-visible();
        }
    }

    .product-tile__refill-check {
        align-items: flex-end;
        align-self: center;
    }

    //The "set--images-lazy-loaded" helper class is set in JS when lazysizes detects that the secondary images are loaded.
    //This prevents displaying a blank square while the secondary images load.
    .set--images-lazy-loaded .product-tile__anchor:focus,
    .set--images-lazy-loaded.product-tile--default:hover {
        .product-tile__image--primary.set--has-secondary-image {
            @include set-invisible();
        }
    }

    .product-tile__image {
        max-height: none;
        transition-property: opacity, visibility, transform;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-in;
    }

    .product-tile__image--secondary {
        @include set-invisible();
    }
}

@include media-query(small) {
    .product-tile--card {
        padding: 1.25rem;

        .product-tile__footer {
            margin-top: 1.25rem;
        }
    }

    .product-tile__overlay {
        margin-top: auto;
    }

    .product-tile__overlay-actions {
        margin-top: .5rem;
        z-index: 10;
    }
}

.modal--refill {
    .product-tile--refill {
        min-height: auto;
    }
}

.product {
    position: relative;
}

.product-tile__packshots {
    position: relative;
    height: 100%;

    .product-tile__image {
        width: 100%;
        height: 100%;
    }

    .product-tile__packshots-slide {
        height: 100%;
    }

    .slick-track,
    .slick-list,
    .product-tile__packshots-img-wrapper {
        height: 100%;
    }

    .product-tile__packshots-img {
        margin: 0 auto;
        @include media-query(small-up) {
            width: auto;
        }
    }

    .slick-arrow {
        bottom: 10px;
        width: 32px;
        height: 32px;
        top: initial;

        &.slick-prev {
            left: 10px;
        }

        &.slick-next {
            right: 10px;
        }

    }

}

.product-list__carousel {
    .product-tile__packshots {
        .slick-arrow {
            top: auto;
        }
    }
}

.product-tile {
    &__quickshop-trigger{
        @include media-query(small) {
            margin: 20px 0 0;
        }
    }

    .product-tile__packshots {

        .slick-arrow,
        .product-slot-image__scrollbar {
            @include media-query(medium-up) {
                visibility: hidden;
            }
        }
    }

    &:hover {

        .product-tile__packshots {

            .slick-arrow,
            .product-slot-image__scrollbar {
                @include media-query(medium-up) {
                    visibility: visible;
                }
            }
        }
    }

}

.product-slot-image__scrollbar {
    bottom: 0;
    height: 50px;
    left: 0;
    position: absolute;
    width: 100%;

    .swiper-scrollbar-drag {
        background-color: $color-black;
        display: inline-block;
        height: 2px;
        left: 0;
        position: absolute;
        bottom: 0;
        width: 25%;
        transition: all 0.1s ease-in;
    }

}

.product-add__button {
    position: relative;

    .add-to-bag__warning {
        display: none;
    }

    &[disabled] {

        &:hover {
            .add-to-bag__warning {
                font-size: .625rem;
                line-height: 2;
                display: block;
                background: $color-black;
                color: $color-white;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                padding: 8px 32px;
                cursor: pointer;
            }
        }

    }
}

.product-add__button {
    &.hide-select-size {
        &:hover {
            .add-to-bag__warning {
                display: none;
            }
        }
    }
}

.product-tile {

    &__quickshop-trigger,
    &__discover-btn {
        opacity: 1;

        @include media-query(medium-up) {
            opacity: 0
        }
    }

    &:after {
        border: 1px solid .0625rem transparent;
        background: $color-white;
        content: "";
        position: absolute;
        left: -12px;
        right: -12px;
        top: -12px;
        bottom: -12px;
        z-index: -1;
    }

    &:not(.strap-view-active) {
        &:hover {
            &:after {
                border: 1px solid .0625rem $color-grey-3;
            }
    
            .product-tile__quickshop {
                opacity: 1;
            }
    
            .product-tile__quickshop-trigger,
            .product-tile__discover-btn {
                @include media-query(medium-up) {
                    opacity: 1
                }
            }
        }
    }

    .product-add__button {
        position: relative;

        .add-to-bag__warning {
            display: none;
        }

        &[disabled] {

            &:hover {
                .add-to-bag__warning {
                    font-size: .625rem;
                    line-height: 24px;
                    display: block;
                    background: $color-black;
                    color: $color-white;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    padding: 8px 0;
                    cursor: pointer;
                }
            }

        }
    }

    .product-tile__quickshop {
        position: initial;
        opacity: 1;

        @include media-query(small) {
            bottom: 0;
            margin: auto 0 0;
            padding-bottom: 0;
        }
        
        .price__sales {
            &.sales {
                font-weight: 400;
                font-size: 1rem;
            }
        }

        .product-tile__quickshop-for-mobile {
            .price__sales {
                &.sales {
                    font-weight: bold;
                }
            }
        }

        p[data-product-component="stock-availability-status"],
        p[data-product-component="limit-status"],
        p[data-product-component="availability-status"],
        .product-get__notify {
            display: none;
        }
        
        .product-tile__anchor-product-details {
            margin-bottom: 15px;
        }

    }

    .product-tile__quickshop-for-mobile {
        display: none;
    }

    &.quick-shop-active {
        position: fixed;
        left: 10px;
        right: 10px;
        bottom: 40px;
        z-index: 10;
        background-color: $color-white;
        animation: slide-up .4s 222ms both;
        width: 95%;
        @include media-query(small) {
            bottom: -10px;
        }

        @include media-query(small-up) {
            position: absolute;
            bottom: auto;
            left: auto;
            right: auto;
            animation: none;
            width: 100%;
        }

        &:after {
            border: 1px solid .0625rem $color-grey-3;
            background-color: $color-white;
            z-index: -1;
        }

        .product-tile__body,
        .product-tile__anchor-product-details {
            display: none;

            @include media-query(medium-up) {
                display: block;
            }
        }

        .product-tile__anchor-product-details {
            font-family: 'Fancy Cut';
        }

        .product-tile__quickshop-for-mobile {
            display: block;
            position: relative;

            @include media-query(medium-up) {
                display: none;
            }

            .product-tile__body-section {
                text-align: left;
            }

            .product-tile__name,
            .product-tile__description {
                margin-bottom: 10px;
            }

            .product-tile__name {
                font-size: 18px;
                .product-tile__anchor {
                    height: 55px;
                }
            }

            .product-tile__description {
                font-size: 16px;
                height: 50px;
                overflow: hidden;
                text-align: left;
                font-family: $font-family-serif;
            }

            .product-tile__wishlist {
                display: block;
                right: 0;
                top: 0;
            }

            .product-tile__anchor-product-details {
                display: block;
                text-align: right;
                font-weight: 400;
            }
        }

        .product-tile__media {
            width: 60%;
            margin: 40px auto auto;

            @include media-query(medium-up) {
                width: 100%;
                margin: auto;
            }
        }

        .product-tile__overlay {
            border-color: transparent;
        }

        .product-tile__quickshop {
            position: relative;
            opacity: 1;
            margin-top: 20px;

            .product-attribute__size {
                margin-bottom: 10px;
                padding: 8px 27px;
                text-align: left;
            }

        }

        .product-tile__wishlist {
            right: 50px;
        }

        .product-tile__discover-btn {
            position: relative;
            bottom: 0;
            margin: 0;
        }

    }

    &__quickshop-options-close-btn {
        background-color: $color-white;
        font-size: 12px;
        width: 30px;
        height: 30px;
        padding-top: 6px;
        border-radius: 50%;
        position: absolute;
        top: 13px;
        right: 10px;
        z-index: 2;

        &:hover:after {
            content: url(get-icon(close, $color-secondary));
        }

        &:after {
            content: url(get-icon(close, $color-body-copy));
            width: 0.76rem;
            height: 0.76rem;
            display: inline-block;
        }
    }

    .product-tile__anchor {
        text-decoration: underline;

        @include media-query(small) {
            z-index: 10;
            margin: auto 0 0;
        }
    }

    .product-tile__name {
        .product-tile__anchor {
            text-decoration: none;
            margin-bottom: 0;
            font-weight: 600;
        }
    }

    .product-tile__discover-btn {
        padding: 7px 0;
        text-decoration: none;
    }

}
.sold-out.product .product-tile:hover::after {
    border: 0;
}

@include media-query(small) {
    .product-grid__item .product.sold-out::before {
        left: 0;
        right: 0;
        top: 20px;
        bottom: 0;
        height: calc(100% - 20px);
    }
    
    .product.sold-out {
        margin: 10px 0;
    }
}
.product.sold-out::before {
    background-color: $color-grey-6    ;
    opacity: 0.25;
    content: "";
    z-index: 2;
    display: flex;
    width: auto;
    position: absolute;
    height: 100%;
    left: 4px;
    right: 4px;
    top: 4px;
    bottom: 4px;
    pointer-events: none;
  }

.quickshop-dialog-overlay {
    overflow: hidden;

    @include media-query(medium-up) {
        overflow: auto;
    }

    &:after {
        content: "";
        background-color: rgba(0, 0, 0, .5);
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;

        @include media-query(small-up) {
            display: none;
        }
    }

    .product-tile {
        z-index: 0;

        &.quick-shop-active {
            .stock-notify-subscription.product-tile__anchor.product-tile__discover-btn {
                opacity: 1;
                position: relative;
            }
            z-index: 8;

            @include media-query(medium-up) {
                z-index: 3;
            }
        }
    }

    .search-results__toolbar {
        .fixit--active {
            z-index: 0;
        }
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(100%)
    }
}

@-webkit-keyframes slide-down {
    0% {
        transform: translateY(0)
    }

    to {
        transform: translateY(100%)
    }
}

@keyframes fade-in {
    0% {
        opacity: 0
    }
}

@keyframes fade-out {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@include media-query(small-up) {
    .product-tile__quickadd {
        button {
            opacity: 0;
        }
    }

    .product-tile {
        &:hover {
            .product-tile__quickadd {
                button {
                    opacity: 1;
                }
            }
        }
    }
    
    .strap-view-active {
        .product-tile__quickadd {
            button {
                opacity: 1;
            }
        }
    }
}

.pdp,
.cart {
    .product-tile {
        &.quick-shop-active {
            margin-bottom: 10px;
            @include media-query(small-up) {
                padding-bottom: 0;
                position: relative;
                margin-bottom: 0;
            }
        }
    }
}

.module-carousel {
    .module-carousel__item {
        .product-tile {
            min-height: 23rem;
    
            @include media-query(small-up) {
                min-height: auto;
            }
        }
    }
}

.product-list {
    &.max-width--xmedium {
        .product-list__carousel {
            .product-tile {
                min-height: 23rem;

                @include media-query(small-up) {
                    min-height: auto;
                }

                .product-badge--novelty {
                    font-size: 0.5rem;
                }
            }
        }
    }
}

.pdp-main__details {
    .product-tile__discover-btn,
    .product-tile__quickshop {
        display: none;
    }
    .product-tile:after {
        border: none;
    }
    .product-tile {
        &:hover {
            &:after {
                border: none;
            }
        }
    }
}

.product-tile__discover-btn,
.product-tile__quickshop-trigger,
.product-tile__quickadd button {
    min-height: 38px;
}

.product-grid__item {
    .product-tile {
        padding-bottom: 60px;
        
        @include media-query(small-up) {
            padding-bottom: 5px;
        }
        
        .product-tile__quickshop,
        .product-tile__discover-btn {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            
            @include media-query(small-up) {
                position: relative;
                margin-top: 1rem;
            }
        }
        .product-tile__quickshop-options {
            .product-tile__discover-btn {
                @include media-query(small-up) {
                    margin-top: 0;
                }
            }
        }
        .product-tile__quickshop-for-mobile {
            .product-tile__anchor {
                font-weight: bold;
                &.product-tile__anchor-product-details {
                    font-weight: 400;
                }
            }
        }
        
        &.quick-shop-active {
            padding-bottom: 0;
            
            .product-tile__quickshop {
                position: relative;
            }
        }
        
        &.flex-grow-1 {
            @include media-query(small-up) {
                flex-grow: initial;
            }
        }
    }
}

[data-lang="fr"] {
    .product-tile {
        .product-attribute--size,
        .product-add__button {
            font-family: sans-serif;
        }
        .product-add__button {
            &[disabled] {
                &:hover {
                    .add-to-bag__warning {
                        padding: 5px 0;
                    }
                }
            }
        }
    }
}

[data-country="RU"] {
    .product-tile,
    .pdp-main__details {
        .product-add__button {
            &[disabled] {
                &:hover {
                    .add-to-bag__warning {
                        font-size: 0.75rem;
                        font-weight: normal;
                        line-height: normal;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 800px){
    .product-image-gallery-swiper{
        background-color: #FFF;
    }
}

