/**
 * Module: Toggle Box
 * Provides styles for toggable box, with title, +/- symbols, and content.
 */

.toggle-box {
    &.error {
        border-color: $color-error;
        background-color: transparentize($color-error, 0.95);
    }

    & + & {
        margin-top: .625rem;
    }
}

.toggle-box--boxed {
    border: solid .0625rem $color-grey-2;

    .toggle-box__label {
        padding-left: 1em;

        &:after {
            right: .75em;
        }
    }

    .toggle-box__content {
        padding-right: 1em;
        padding-left: 1em;
    }
}

.toggle-box--single {
    & + & {
        border-top: none;
    }
}

    .toggle-box__label {
        display: flex;
        align-items: center;
        position: relative;
        min-height: 2.75rem;
        padding-top: .5rem;
        padding-right: 2em;
        padding-bottom: .5rem;
        text-align: left;
        scroll-padding-top: 5.5rem;
        cursor: pointer;


        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: .875rem;
            height: .875rem;
            content: url(get-icon(angle-down, $color-body-copy));

            @supports (mask-size: cover) {
                content: '';
                mask: url(get-icon(angle-down, $color-body-copy));
                mask-size: cover;
                background-color: currentColor;
            }
        }

        &.toggle--active {
            &:after {
                transform: scale(-1);
            }
        }
    }

    .toggle-box__content {
        overflow: hidden;
        max-height: 0;
        visibility: hidden;
        transition: visibility $transition-speed-fast $transition-ease-in,
                    max-height $transition-speed-fast $transition-ease-in;

        > * {
            opacity: 0;
            transition: opacity $transition-speed-fast $transition-ease-out;
        }

        //You can add expanding transitions by setting a local max-height
        //on an element with the "toggle-box__content--<MODIFIER>" class.
        &.toggle--active {
            overflow: initial;
            max-height: none;
            padding-top: .25em;
            padding-bottom: 2em;
            // padding-bottom: 1.25rem;
            visibility: visible;

            > * {
                opacity: 1;
                transition-delay: $transition-speed-fastest;
            }
        }

        //Intentional heavy selector to force no padding on content.
        &.toggle-box__content--flush {
            padding: 0;
        }
    }
