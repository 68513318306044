
.link {
    transition: opacity $transition-speed-faster $transition-ease-default;

    &:hover {
        opacity: .6;
    }
}

.link--primary,
.link--secondary,
.link--secondary--static,
.link--secondary--highlight,
.link--secondary-icon {
    position: relative;
    padding-bottom: .25em;
    // line-height: $font-line-height-hecto;

    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;

    transition-property: opacity, color, background-size;

    &:not(.link--hide-external)[target="_blank"] {
        &:after {
            display: inline-block;
            width: .7em;
            height: 1em;
            margin-left: .25em;
            content: url(get-icon(external, $color-body-copy));
        }
    }
}

.link--secondary-icon {
    margin-left: 2.25rem;

    .link__icon {
        position: absolute;
        left: -2.25rem;

        @include vertical-align(false, true);
    }
}

.link--primary {
    font-family: $font-family-serif;
    background-size: 100% .0625rem;

    transition-timing-function: $transition-ease-in;
    transition-duration: $transition-speed-faster;

    &:hover {
        background-size: 0 .0625rem;

        transition-duration: $transition-speed-normal;
        transition-timing-function: $transition-ease-out;
    }
}

.link--secondary,
.link--secondary--highlight,
.link--secondary-icon {
    text-transform: uppercase;
    background-size: 0 .0625rem;

    transition-timing-function: $transition-ease-out;
    transition-duration: $transition-speed-normal;

    &:hover {
        background-size: 100% .0625rem;

        transition-duration: $transition-speed-faster;
        transition-timing-function: $transition-ease-in;
    }
}

.link--secondary--highlight {
    padding-bottom: .7em;
    background-size: 0 .1875rem;
    background-image: linear-gradient($color-secondary, $color-secondary);

    &.link--secondary--static {
        background-size: 100% .1875rem;
    }

    &:hover {
        background-size: 100% .1875rem;
    }
}

.link--secondary--static {
    text-transform: uppercase;
    background-size: 100% .0625rem;
}

.link--inner-icon-spacing {
    &[target="_blank"] {
        &:after {
            margin-left: .75em;
        }
    }
}

.link--underline {
    text-decoration: underline;
    @include textUnderlineUnder();
}

.link--underline-hover {
    @include textUnderlineUnder();

    &:hover, &:focus {
        text-decoration: underline;
    }
}

.link--highlight-hover {
    &:hover, &:focus {
        opacity: 1;
        color: $color-secondary;
    }
}

.actions__cta-container {
    display: flex;

    img {
        margin-right: .6em;
        height: 1em;
        width: 1em;
    }
}

//Necessary override to avoid some difficult choices.
.link--flex {
    display: inline-flex;
}

/**
 *  Utility classes for buttons and pseudo buttons
 */
.cursor--pointer:hover {
    cursor: pointer;
}

.cursor--not-allowed:hover {
    cursor: not-allowed;
}

a.checkout-steps-header__message-link-policy {
    text-decoration: underline;
}
