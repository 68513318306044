
.dropdown-box {
    position: relative;
    text-transform: uppercase;
}

    .dropdown-box__trigger {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis;
        border-bottom: solid .0625rem $color-body-copy;
        border-radius: $input-border-radius;
        text-transform: inherit;
    }

    .dropdown-box__list {
        z-index: 2;
        position: absolute;
        top: calc(100% - .0625rem);
        right: 0;
        min-width: 100%;
        background-color: $color-input-background;
        border: solid .0625rem $color-grey-4;
        border-radius: 0 0 $input-border-radius $input-border-radius;

        &:not(.toggle--active) {
            @include set-invisible();
        }
    }

    .dropdown-box__item {
        &:not(:last-child) {
            border-bottom: solid .0625rem $color-grey-1;
        }
    }

    .dropdown-box__anchor {
        display: block;
        font-size: $font-size-deci;

        &:hover, &:focus {
            background-color: transparentize($color-body-copy, .85);
        }

        &.selected {
            font-weight: $font-weight-semibold;
            background-color: $color-primary;
            color: $color-white;
        }
    }

@include media-query(small-up) {
    .dropdown-box__anchor {
        padding: .75rem 1rem;
    }
}

@include media-query(small) {
    .dropdown-box__list--small-expanded {
        position: relative;
        top: initial;
        border: none;

        &:not(.toggle--active) {
            @include set-visible();
        }
    }

    .dropdown-box__anchor {
        padding: 1.25rem .75rem;
    }
}