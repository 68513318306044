/*------------------------------------*\
  #MODULE COMPONENTS
\*------------------------------------*/

@import "~core/modules/moduleCommons";

.component-outer-h-align--right {
    margin-left: 10px;
}

.autocomplete {
  position: relative;
  display: inline-block;
}

ul.autocomplete-items {
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.autocomplete-items li {
  padding: 10px;
  cursor: pointer;
  background-color: #fff; 
  border: 1px solid #d4d4d4;
  border-top: none;
  font-size: 0.7rem;
}

/*when hovering an item:*/
.autocomplete-items li:hover {
  background-color: #e9e9e9; 
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: black !important; 
  color: #ffffff; 
}

.loqate-address-container {
  background-image: url("../images/Right-Arrow.png");
  background-repeat: no-repeat;
  background-position: right 7px center;
}
