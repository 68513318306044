
.header__search {

}

    .site-search__form {
        z-index: 5;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        background-color: $header-nav-panel-bg;
        padding-top: 2rem;

        transition-property: opacity, visibility;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-out;

        &:not(.toggle--active) {
            @include set-invisible();
            pointer-events: none;
        }
    }

    .site-search__form-group {
        position: relative;
        input[type=search] {
            padding-right: 5px;
        }
    }

    .site-search__field {
        padding-right: 2rem;

        //In case there are transitions applied to the containing form element.
        //This allows focus to be shifted even if the form is still invisible (in transition).
        .toggle--active & {
            visibility: visible;
        }
    }

    .site-search__button-col {
        max-width: 7rem;
    }

    .site-search__filters {
        margin-top: 1.25rem;
    }

    .header__search-trigger {
        position: relative;

        &.toggle--active {
            .site-search__trigger-search {
                @include set-invisible();
            }

            &:hover{
                color: $color-secondary;
                opacity: 1;
            }
        }

        &:not(.toggle--active) {
            .site-search__trigger-close {
                @include set-invisible();
            }
        }
    }

    .site-search__trigger-icon {
        @include vertical-align(true);
    }

    .site-search {
        .site-search__form {
            .search-close-btn {
                position: absolute;
                top: 35px;
                left: auto;
                right: 35px;
                z-index: 6;
                @include media-query(small) {
                    right: auto;
                    left: 16px;
                }
            }
        }  
    }

.site-search__suggestions-container {
    @include media-query(small-up) {
        padding: 0 2rem 4rem 2rem;
        max-width: 112.5rem;
    }
    .search-results--null__title {
        max-width: 490px;
        margin: 2rem auto;
        padding: 0 .5rem;
        .query {
            font-weight: 700;
        }
    }
    .search-no-results {
        .popular-search--container, .site-search__content-container {
            display: block;
        }
    }
}

    .site-search__suggestions-list {
        flex: 1 1 100%;
        width: 100%;
        padding-bottom: 1.25rem;
        border-radius: 0 0 .125rem .125rem;
        border-top: none;
        background-color: $color-white;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }

    .no-editorial {
        .site-search__suggestions-section--products {
            @media screen and (min-width: 1024px) {
                max-width: 50%;
            }
            @media screen and (min-width: 1200px) {
                max-width: 100%;
            }
        }
    }

        .site-search__suggestions-section {
            &:not(:last-child) {
                margin-bottom: 2rem;
                @include media-query(small) {
                    margin-bottom: 3rem;
                }
            }
            &--products {
                @include media-query(small-up) {
                    align-items: flex-end;
                    padding-top: 2rem;
                    &:not(:last-child) {
                        padding-right: 48px;
                        border-right: 1px solid #e7e7e7;
                    }
                }
                .site-search__suggestions-matches {
                    flex-wrap: wrap;
                    @include media-query(small-up) {
                        justify-content: flex-end;
                        align-self: stretch;
                    }
                }
                .site-search__suggestions-item {
                    padding: 0 1rem 0 0;
                    @include media-query(small) {
                        &:nth-child(odd) {
                            padding-right: .5rem;
                            padding-left: 0;
                        }
                        &:nth-child(even) {
                            padding-right: 0;
                            padding-left: .5rem;
                        }
                    }
                    .product-tile__body-section {
                        .price {
                            display: none;
                        }
                    }
                }
            }
            &--content, &--customer {
                @include media-query(small-up) {
                    padding: 2rem 1.5rem 0 3rem;
                }
            }
            .site-search__suggestions-view--all {
                align-self: flex-start;
                background-image: linear-gradient(currentColor, currentColor);
                background-position: 0 98%;
                background-repeat: no-repeat;
                background-size: 0 1px;
                background-size: 100% 1px;
                display: inline;
                line-height: 1.2;
                padding-bottom: 2px;
                padding-top: 2px;
                text-decoration: none;
                transition: background-size 266ms ease-out;
                &:hover {
                    background-size: 0 1px;
                }
            }
        }

        .search-suggestions__editorial {
            li.site-search__suggestions-item {
                margin-bottom: 2rem;
                .site-search__suggestions-name {
                    position: relative;
                    display: block;
                    .site-search__arrow--icon {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        min-width: 40px;
                        min-height: 14px;
                        padding-left: 2px;
                        svg.icon {
                            @include bidirectionalProperty('transform', rotate(0deg), rotate(180deg));
                        }
                    }
                    .editorial-info {
                        max-width: calc(100% - 40px);
                        .editorial-title {
                            font-weight: 400;
                            font-size: .875rem;
                            text-transform: uppercase;
                            background-image: linear-gradient(currentColor, currentColor);
                            background-position: 0 98%;
                            background-repeat: no-repeat;
                            background-size: 0 1px;
                            display: inline;
                            line-height: 1.6;
                            padding-bottom: 2px;
                            padding-top: 2px;
                            text-decoration: none;
                            transition: background-size .2s ease-in;
                            &:hover {
                                background-size: 100% 1px;
                            }
                        }
                        .site-search__suggestions-description {
                            text-transform: none;
                            margin-top: 5px;
                            font-size: 1rem;
                        }
                    }
                }
            }
        }

            .site-search__suggestions-corrections {
                margin-top: 1.25rem;
            }

            .site-search__suggestions-title {
                margin-bottom: 1.5rem;
                font-size: 1rem;
                width: 100%;
                span {
                    padding: 0 4px;
                } 
            }

            .suggestions-products {
                &--1-results {
                    .site-search__suggestions-title {
                        width: calc(50% - 14px);
                        display: flex;
                        @include media-query(medium-up) {
                            width: calc(25% - 14px);
                        }
                    }
                }
                &--2-results {
                    .site-search__suggestions-title {
                        width: calc(50% - 7px);
                    }
                }
                &--3-results {
                    .site-search__suggestions-title {
                        width: 75%;
                    }
                }
                &--4-results {
                    .site-search__suggestions-title {
                        width: 100%;
                    }
                }
            }

            .site-search__suggestions-item {
                &:not(:last-child) {
                    margin-bottom: .75rem;
                }
                .product-tile {
                    padding: 0;
                    min-height: 0;
                    @include media-query(small) {
                        margin: 0;
                    }
                    &:not(.strap-view-active):hover {
                        &::after {
                            border: none;
                        }
                    }
                    &:hover {
                        .product-tile__wishlist {
                            opacity: 0;
                        }
                    }
                }
            }

                .site-search__suggestions-image-wrap {
                    overflow: hidden;
                    @include aspect-ratio(1, 1, true);
                }

                .site-search__suggestions-image {
                    max-height: none;
                    @include vertical-align(true);
                }

                .site-search__suggestions-banner {
                    margin-bottom: .5rem;
                }

@include media-query(small-up) {
    .header__search {
        margin-top: -.75rem;
        margin-left: .875rem;
        border-left: solid .0625rem;
    }

        .header__search-trigger {
            width: 4rem;
            height: 1.25rem;
        }

    .site-search__form {
        min-height: 13.125rem;
        max-height: calc(100vh - 3rem);
        overflow-y: auto;
    }

    .site-search__form-group {
        max-width: 30.375rem;
    }
}

@include media-query(small) {
    .site-search__form {
        min-height: 11.5rem;
        max-height: 100vh;
        box-shadow: 0 -.0625rem 0 0 transparentize($color-black, .85);
        overflow-y: auto;
        overflow-x: hidden;
    }

        .header__search-trigger {
            width: 2rem;
            height: 2rem;
        }
}

.search-header__logo {
    margin-bottom: 2rem;
}

.popular-search--container {
    margin-top: 2rem;
    width: 100%;
}

ul.popular-search--list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 0 2rem 0;
    @include media-query(small) {
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow-x: auto;
    }
}

li.popular-search--items {
    list-style: none;
    padding: 0;
    border: 1px solid #ccc;
    margin: 0 16px 0 0;
    font-size: .75rem;
    a {
        padding: 12px 16px;
        display: block;
        text-decoration: none;
        white-space: nowrap;
        text-transform: uppercase;
    }
}

.site-search__content-container {
    h2.content-tile__title {
        font-size: 1rem;
        margin-bottom: .5rem;
    }
}

[data-page-motion] {
   .site-search__content-container,
   .site-search__suggestions-list {
        [data-motion]:not(.set--motion-trigger) {
            opacity: 1;
            text-align: left;
        }
   } 
}

.search-empty__section {
    &--be-inspired {
        @include media-query(small-up) {
            padding-right: 2.25rem;
            border-right: 1px solid #ccc;
        }
    }
    &--articles {
        padding-top: 2rem;
        padding-bottom: 2rem;
        @include media-query(small-up) {
            padding-left: 3rem;
        }
    }
    &--customer-care {
        //padding-top: 2rem;
        padding-bottom: 2rem;
        &:first-child {
            max-width: 100%;
            flex-basis: 100%;
            @include media-query(small-up) {
                padding-left: 4rem;
            }
        }
        @include media-query(small-up) {
            padding-left: 3rem;
        }
    }
}

// @include media-query(small-up) {
//     .search-empty__section {
//         &--be-inspired {
//             padding-right: 3rem;
//             border-right: 1px solid #ccc;
//         }
//         &--articles {
//             padding-left: 3rem;
//             padding-top: 2rem;
//         }
//         &--customer-care {
//             padding-left: 1.5rem;
//         }
//     }
// }

.search-be__inspired {
    .module-container__header:not(:last-child) {
        margin-bottom: 0;
        .content-tile__content-wrap {
            padding-top: 2rem;
        }
    }
    .module-grid {
        .content-tile__content-wrap {
            padding-top: .5rem;
        }
        .content-tile__title {
            font-weight: 400;
            font-size: 12px;
        }
    }
}

.search-articles__item {
    margin-bottom: 1rem;
}

ul.search-articles__list {
    padding: 0;
    list-style: none;
    margin-top: 1rem;
}

.search-articles__item {
    position: relative;
    & > a {
        position: static;
        & > .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            @include bidirectionalProperty('rotate', 0deg, 180deg);
        }
    }
}

.search-customer-care {
    background-color: $color-grey-1;
    padding: 2rem 1rem 1.5rem;
    margin-left: -1rem;
    margin-right: -1rem;
    .search-customer-care__heading {
        padding-left: .5rem;
        padding-right: .5rem;
    }
}

.search--customer-care__wrapper {
    .module-container__header {
        &:not(:last-child) {
            margin-bottom: 0;
        }
    }
    .content-tile__content-wrap.set--text-after {
        padding: 0;
    }
    h2.content-tile__title {
        padding: 0 .5rem;
        font-size: 1rem;
        margin-bottom: .5rem;
    }
    .module-grid__item {
        .icon-tile {
            display: flex;
            align-items: center;
            padding: 0 .75rem;
            margin: 1rem 0;
            .icon-tile__media {
                width: 24px;
                height: 24px;
            }
            .component-overlay {
                &:hover {
                    & + .icon-tile__content {
                        .icon-tile__title {
                            background-size: 100% 1px;
                        }
                    }
                }
            }
            .icon-tile__content {
                padding-left: 1rem;
                margin-top: 0;
                .icon-tile__title {
                    font-size: 0.875;
                    font-weight: 400;
                    background-image: linear-gradient(currentColor, currentColor);
                    background-position: 0 98%;
                    background-repeat: no-repeat;
                    background-size: 0 1px;
                    display: inline;
                    line-height: 1.6;
                    padding-bottom: 2px;
                    padding-top: 2px;
                    text-decoration: none;
                    transition: background-size .2s ease-in;
                }
            }
        }
    }
}

.site-search__suggestions-corrections {
    text-align: left;
    margin-top: 1rem;
    width: 100%;
    align-self: center;
    @include media-query(small-up) {
        max-width: 30.375rem;
    }
    a.site-search__suggestions-correction {
        display: block;
        text-transform: uppercase;
        font-family: 'Brilliant Cut';
        margin-bottom: 1rem;
        font-size: 14px;
        text-decoration: none;
        .highlight {
            font-weight: 400;
        }
        &:not(.highlighted-suggestion) {
            display: none;
        }
    }
}

.search-only__article--results, .search-only__customer--results {
    .suggestions-article-customer--container {
        justify-content: center;
    }
}

.search-only__product--results {
    @include media-query(small-up) {
        .suggestions-products--1-results {
            transform: translateX(-37.5%);
        }
        .suggestions-products--2-results {
            transform: translateX(-25%);
        }
        .suggestions-products--3-results {
            transform: translateX(-12.5%);
        }
    }
}

[data-search-component="query-input"] {
    &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        background: url("data:image/svg+xml,%3csvg width='10' height='10' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M.436 0a.434.434 0 0 0-.303.746l4.253 4.253L.133 9.253a.434.434 0 0 0 .602.626l.012-.012L5 5.613l4.253 4.254a.434.434 0 1 0 .626-.603l-.012-.011-4.253-4.254L9.867.746a.434.434 0 1 0-.614-.614L5 4.385.747.132A.434.434 0 0 0 .436 0' fill-rule='evenodd'/%3e%3c/svg%3e") no-repeat 50% 50%;
        background-size: 12px 12px;
        height: 30px;
        width: 45px;
    }
}

.search-article-customer--results,.search-only__article--results,.search-only__customer--results {
    @include media-query(small) {
        padding-top: 2rem;
    }
}