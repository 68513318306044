
.puck-container {
    position: relative;
}

    /*! rtl:begin:ignore */
    .puck {
        display: block;
        position: absolute;
        left: 0;
        bottom: -.0625rem;
        width: 0;
        height: .1875rem;
        background-color: $color-secondary;

        &:not(.set--cancel-transitions) {
            transition-property: width, transform;
            transition-duration: $transition-speed-fast;
            transition-timing-function: $transition-ease-out;
        }
    }
    /*! rtl:end:ignore */
