
.utility-overlay {
    display: flex;
    flex-direction: column;
    z-index: 11;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    background-color: $color-panel-background;
    // border-radius: .25rem;
    box-shadow: 0 .5rem 2rem transparentize($color-black, .95);
    cursor: default; //Resets cursor when used in combination with an overlay.

    &.toggle--active {
        transition: transform $transition-speed-normal $transition-ease-bezier,
                    visibility 0s;
    }

    &:not(.toggle--active) {
        visibility: hidden;
        transform: translateX(100%);
        transition: transform $transition-speed-fast $transition-ease-in,
                    visibility 0s $transition-ease-in $transition-speed-fast;
    }
}


    .utility-overlay__header,
    .utility-overlay__footer {
        flex-shrink: 0;
    }

    .utility-overlay__header {
        position: relative;
        padding-top: 1.55rem;
        border-bottom: solid .0625rem $color-grey-3;
    }

        .utility-overlay__header-title {
            margin-bottom: 1.55rem;
        }

        .utility-overlay__header-close {
            position: absolute;
            top: 0;
            right: 0;
            height: 4.75rem;
            padding: 1rem 1.25rem;
        }

    .utility-overlay__line-items {
        // flex-grow: 1;
        flex-shrink: 1;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        // border-bottom: solid .0625rem $color-grey-3;
        .product-line-item--bonusStrap {
            .product-line-item__actions {
                display: none;
            }
        }
    }

    .utility-overlay__footer {
        margin-top: auto;
        // background-color: transparentize($color-black, .95);
    }

        .utility-overlay__footer-section {
            border-top: solid .0625rem $color-grey-3;
        }

        .utility-overlay__footer-totals,
        .utility-overlay__footer-promo {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }

        .utility-overlay__footer-message {
            margin-top: .5em;
        }

        .utility-overlay__footer-actions {
            margin-top: 1rem;
        }

@include media-query(small-up) {
    .utility-overlay__footer {
        padding-right: 3.375rem;
        padding-left: 3.375rem;
        padding-bottom: 1.85rem;
    }
}

@include media-query(small) {
    .utility-overlay__footer {
        padding-right: 1rem;
        padding-left: 1rem;
        padding-bottom: 1rem;
    }
}
