
.subscription-banner {
    border-top: solid .0625rem $color-grey-3;
    border-bottom: solid .0625rem $color-grey-3;
}

@include media-query(small-up) {
    .subscription-banner {
        margin-top: 5.625rem;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

        .subscription-banner__description {
            margin-bottom: 1.75rem;
        }
}

@include media-query(medium) {
    .subscription-banner__description {
        margin-bottom: .85em;
    }
}

@include media-query(small) {
    .subscription-banner {
        margin-top: 3.75rem;
        padding-top: 3.125rem;
        padding-bottom: 3.125rem;
    }

        .subscription-banner__description {
            margin-bottom: 2.25rem;
        }
}

.unsubscribe-confirmation__detail {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 7% 0;
    h1 {
        margin-bottom: 1rem;
    }
}