@import "~core/modules/modals";

/*------------------------------------*\
  #PLACE OVERRIDES HERE
\*------------------------------------*/

.modal--account-subscriptions {
    .window-modal__content {
        max-width: 40rem;

        .collection--heading {
            padding: 2rem 5rem;
            border-top: 4px solid $color-red;
            border-bottom: 1px solid $color-grey-2;
        }

        .collection--section {
            padding: 2rem 2rem 2rem 13rem;
        }

        .collection--action-buttons {
            padding: 2rem;
            border-top: solid 1px $color-grey-2;
        }
    }
}

.modal--law-label {
    justify-content: center;
    align-items: center;
    .window-modal__content {
        max-width: 22rem;
        padding: 1.6rem 0;
        min-height: auto;
        margin: 0 auto;
    }

    .window-modal__close {
        top: 0;
        right: 0;
    }
}

@include media-query(small-up) {
    .modal--generic {
        .window-modal__content {
            padding: 1rem;
        }
    }
}


#modal-getStockNotification {
    
    .window-modal {
    
        &__content {
            padding: 0;
        }
        
    }

}
