.iti__container {
  position: relative;
}

.iti {
  position: relative;
  display: flex;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input[type=tel] {
  padding-left: .5rem !important;
}

.iti__input {
  /*! rtl:ignore*/
  border-left: solid .0625rem;
}

.iti__flag-container,
.iti__dropdown-placeholder {
  width: 5.375rem;
  flex: 0 0 5.375rem;
  border-bottom: solid .0625rem;
}

.iti__dropdown-placeholder {
  .set--intl-tel-ready & {
    display: none;
  }
}

.iti__dropdown-label {
  position: absolute;
  top: -1rem;
}

/*! rtl:ignore*/
[dir="rtl"] .iti__dropdown-label {
    left: 0;
    margin-left: 1rem;
    top: -1.25rem;
}

.iti__input-label {
  .set--intl-tel-ready & {
    @include bidirectionalProperty(margin-left, 5.875rem, .5rem);
  }
}

.shipping_phoneNumber {
  .no_other_country_pre {
    .iti__country-list {
      .iti__standard {
        display: none;
      }
    }
  }
  .removeArrowImage {
    .iti__selected-flag {
      background-image: none;
    }
  }
}

.no_other_country_pre_mobile {
  .iti__country-list {
    .iti__standard {
      display: none;
    }
  }
}

.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  @include bidirectionalProperty(flex-direction, $rtlValue: row-reverse);

  /*! rtl:begin:ignore*/
  padding-left: .5rem;
  padding-right: 1.75rem;
  background-position: calc(100% - .65em) center;
  /*! rtl:end:ignore*/

  background-size: .85rem .85rem; //Provide height + width separately to fix IE display.
  background-repeat: no-repeat;
  background-image: url(get-icon(angle-down, $color-body-copy));

  font-weight: $font-weight-light;
  outline-offset: 0;
}

  .iti__selected-dial-code {
    @include bidirectionalProperty(margin-left, $rtlValue: auto);
    @include bidirectionalProperty(direction, $rtlValue: ltr);
  }

.iti__country-list {
  position: absolute;
  top: 100%;
  @include bidirectionalProperty(right, $rtlValue: 0);
  z-index: 2;
  list-style: none;
  text-align: left;
  padding-left: 0;
  background-color: white;
  border: solid .0625rem;
  border-top: none;
  width: 100%;
  max-height: 12.5rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition-property: opacity, visibility, max-height;
  transition-duration: $transition-speed-fast;
  transition-timing-function: $transition-ease-bezier;

  &.iti__hide {
    display: block;
    max-height: 3rem;

    @include set-invisible();
  }
}

.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

.iti__flag-box {
  display: none;
}

.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid $color-grey-5;
}

.iti__country {
  padding: .25rem .5rem;
  outline: none;
  font-size: $font-size-deci;
}

.iti__dial-code {
  // color: $color-secondary;
  font-weight: $font-weight-semibold;
}

.iti__country.iti__highlight {
  background-color: $color-grey-2;
}

.iti__country-name,
.iti__dial-code {
  vertical-align: middle;
}

/*! rtl:begin:ignore */
.iti__country {
  direction: ltr;
}

.iti__country-name {
  margin-right: 6px;
}
/*! rtl:end:ignore */

.iti--container:hover,
.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}

.iti--allow-dropdown input[disabled]+.iti__flag-container:hover,
.iti--allow-dropdown input[readonly]+.iti__flag-container:hover {
  cursor: default;
}

.iti--separate-dial-code{
  @include bidirectionalProperty(flex-direction, $rtlValue: row-reverse);
}

.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}

.iti-mobile .iti--container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize($color-black, 0.75);
}

.iti-mobile .iti__country-list {
  top: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
  max-height: calc(100% - 2rem);
  border: none;
}

.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  display: none;
}