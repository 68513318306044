
.promo-banner {
    padding-top: .6125rem;
    padding-bottom: .6125rem;
}

.promo-banner--size-large {
    font-size: $font-size-deka;
}

.promo-banner--size-small {
    @include bidirectionalProperty(font-size, $font-size-centi, $font-size-deci);
}

.promo-banner__body {
    display: inline;
}

.promo-banner__actions {
    display: inline-block;
    margin-left: .25rem
}

.promo-banner__cta {
    &:not(:last-child) {
        margin-right: 1rem;
    }
}