
.login__tab-item {
    @include textUnderlineUnder();

    &.toggle--active {
        -webkit-text-stroke-width: .0625rem;
        text-decoration: underline;
    }
}

@include media-query(small-up) {
    .login {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    .login--sso-landing {
        margin-top: 1.25rem;
    }

        .login__section {
            &:not(:last-child) {
                margin-bottom: 3.75rem;
            }
        }

        .login__section--padded {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }

        .login__section-content {
            max-width: 23.5rem;
            margin: 0 auto;
        }

        .login__tab-list--page {
            margin-bottom: 4.875rem;
        }

        .login__tab-list--wishlist-page {
            margin-bottom: 2rem;
        }

        .login__tab-item {
            &:not(:last-child) {
                margin-right: 3.5rem;
            }
        }

        .login__actions--sso-landing {
            margin-top: 2.25rem;
            position: relative;
            max-width: 600px;
        }
}

@include media-query(small) {
    .login {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .login--sso-landing {
        margin-top: .5rem;
    }

    .login__section {
        &:not(:last-child) {
            margin-bottom: 4.5rem;
        }
    }

    .login__section--padded {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    .login__tab-list--page,
    .login__tab-list--wishlist-page {
        margin-bottom: 1rem;
    }

    .login__tab-item {
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    .login__actions--sso-landing {
        margin-top: 1.5rem;

        .button {
            max-width: 15.25rem;
        }
    }
}

.ssoLoginError {
    margin-top: 2rem;
}